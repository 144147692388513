export const SET_SELECTED_OBJECT = 'alchemist/{0}/SET_SELECTED_OBJECT';
export const BROWSE_SELECTED_OBJECT = 'alchemist/{0}/BROWSE_SELECTED_OBJECT';
export const MARK_OBJECT = 'alchemist/{0}/MARK_OBJECT';
export const MARK_OBJECTS = 'alchemist/{0}/MARK_OBJECTS';
export const MARK_ALL = 'alchemist/{0}/MARK_ALL';
export const CLEAR_MARKS = 'alchemist/{0}/CLEAR_MARKS';
export const LOAD_CONTENT = 'alchemist/{0}/LOAD_CONTENT';
export const LOAD_CONTENT_SUCCESS = 'alchemist/{0}/LOAD_CONTENT_SUCCESS';
export const LOAD_CONTENT_ERROR = 'alchemist/{0}/LOAD_CONTENT_ERROR';
export const INITIAL_LOAD = 'alchemist/{0}/INITIAL_LOAD';
export const LOAD_NEXT_PAGE = 'alchemist/{0}/LOAD_NEXT_PAGE';
export const LOAD_NEXT_PAGE_SUCCESS = 'alchemist/{0}/LOAD_NEXT_PAGE_SUCCESS';
export const SET_HAS_MORE = 'alchemist/{0}/SET_HAS_MORE';
export const SET_TOTAL_COUNT = 'alchemist/{0}/SET_TOTAL_COUNT';

export const SET_TEMP_FILTER = 'alchemist/{0}/SET_TEMP_FILTER';
export const SET_ADV_FILTER = 'alchemist/{0}/SET_ADV_FILTER';
export const REMOVE_TEMP_FILTER = 'alchemist/{0}/REMOVE_TEMP_FILTER';
export const REMOVE_ADV_FILTER = 'alchemist/{0}/REMOVE_ADV_FILTER';
export const SET_SEARCH = 'alchemist/{0}/SET_SEARCH';
export const SET_SEARCH_OPERATOR = 'alchemist/{0}/SET_SEARCH_OPERATOR';
export const SET_CATEGORY = 'alchemist/{0}/SET_CATEGORY';
export const SET_FILTERS_AND_SORT = 'alchemist/{0}/SET_FILTERS_AND_SORT';
export const SET_SORT = 'alchemist/{0}/SET_SORT';
export const TOGGLE_SORT = 'alchemist/{0}/TOGGLE_SORT';
export const SET_MAX_PAGE = 'alchemist/{0}/SET_MAX_PAGE';
export const SET_MAX_PAGE_CONTENT = 'alchemist/{0}/SET_MAX_PAGE_CONTENT';
export const APPLY_TEMP_FILTERS = 'alchemist/{0}/APPLY_TEMP_FILTERS';
export const APPLY_TEMP_FILTERS_GROUP = 'alchemist/{0}/APPLY_TEMP_FILTERS_GROUP';
export const APPLY_TEMP_FILTERS_KEY = 'alchemist/{0}/APPLY_TEMP_FILTERS_KEY';
export const REVERT_TEMP_FILTERS = 'alchemist/{0}/REVERT_TEMP_FILTERS';
export const REVERT_TEMP_FILTERS_GROUP = 'alchemist/{0}/REVERT_TEMP_FILTERS_GROUP';
export const CLEAR_FILTERS_GROUP = 'alchemist/{0}/CLEAR_FILTERS_GROUP';
export const CLEAR_FILTERS_KEY = 'alchemist/{0}/CLEAR_FILTERS_GROUP';
export const CLEAR_FILTERS = 'alchemist/{0}/CLEAR_FILTERS';
export const SET_ADV_FILTERS_OPERATOR = 'alchemist/{0}/SET_ADV_FILTERS_OPERATOR';

export const UNLIST_ELEMENT = 'alchemist/{0}/UNLIST_ELEMENT';

export const OPEN_ADV_FILTER_ELEMENT = 'alchemist/{0}/OPEN_ADV_FILTER_ELEMENT';
export const CLOSE_ADV_FILTER_ELEMENTS = 'alchemist/{0}/CLOSE_ADV_FILTER_ELEMENTS';
export const TOGGLE_ADV_FILTERS_WINDOW = 'alchemist/{0}/TOGGLE_ADV_FILTERS_WINDOW';
export const TOGGLE_FILTERS_UI_SECTION = 'alchemist/{0}/TOGGLE_FILTERS_UI_SECTION';
export const SET_IS_SEARCH_FOCUSED = 'alchemist/{0}/SET_IS_SEARCH_FOCUSED';
export const SET_FILTER_GROUPS_OPERATORS = 'alchemist/{0}/SET_FILTER_GROUPS_OPERATORS';
export const SET_INDIVIDUAL_OPTIONS_OPERATORS = 'alchemist/{0}/SET_INDIVIDUAL_OPTIONS_OPERATORS';

export const AND_OP = 'and';
export const OR_OP = 'or';

export const EQ_OP = 'eq';
export const NE_OP = `~${EQ_OP}`;

export const SEARCH_OP = 'search';
export const SEARCH_NOT_OP = `~${SEARCH_OP}`;

export const GE_OP = 'ge';
export const GE_NOT_OP = `~${GE_OP}`;

export const ANY_OP = 'any';
export const ANY_NOT_OP = `~${ANY_OP}`;

export const BETWEEN_OP = 'between';
export const BETWEEN_NOT_OP = `~${BETWEEN_OP}`;

export const ENCLOSED_OP = 'enclosed';
export const ENCLOSED_NOT_OP = `~${ENCLOSED_OP}`;

export const IN_OP = 'in';
export const IN_NOT_OP = `~${IN_OP}`;

export const SET_SEARCH_WITHOUT_FILTERS = 'alchemist/{0}/SET_SEARCH_WITHOUT_FILTERS';
export const SET_TRIED_SEARCH_WITHOUT_FILTERS = 'alchemist/{0}/SET_TRIED_SEARCH_WITHOUT_FILTERS';
export const SET_CONTENT_LOADING = 'alchemist/{0}/SET_CONTENT_LOADING';

