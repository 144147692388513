import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

import DefaultSaveButton from 'components/Form/DefaultSaveButton';
import DefaultCloseButton from 'components/Form/DefaultCloseButton';
import DefaultButtonsWrapper from 'components/Form/DefaultButtonsWrapper';
import ErrorBox from 'components/Form/ErrorBox';
import NoteLegend from 'components/Generic/NoteLegend';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';

import { SERVER_ERROR } from './constants';

export const SubmissionContext = React.createContext();

export const SERVER_ERROR_MESSAGE = (
  <>
    <p>
      This is really embarrassing! Something went wrong on our server while processing your request.
    </p>
    <br />
    <p>
      We will work on this issue on our end.
      If you would like to provide us with more details,
      please use the Intercom bubble in the right bottom corner
      or send us an email at <a href="mailto:dev@alchemistaccelerator.com">dev@alchemistaccelerator.com</a>.
    </p>
  </>
);

const InnerButtonsWrapper = ({ removeInnerButtonsWrapper, children, innerButtonsWrapperClassName }) => removeInnerButtonsWrapper ? (
  children
) : (
  <div className={`mb-md-5 mb-0 w-mob-100 ${innerButtonsWrapperClassName || ''}`}>{children}</div>
);

class FormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.updateSubmissionContextProps();
  }

  componentDidUpdate() {
    this.updateSubmissionContextProps();
  }

  updateSubmissionContextProps() {
    const submissionContextProps = pick(this.props, 'handleSubmit', 'save', 'isSubmitDisabled', 'submitting');
    if (!this.submissionContextProps || !isEqual(submissionContextProps, this.submissionContextProps)) {
      this.submissionContextProps = submissionContextProps;
    }
  }

  render() {
    const {
      handleSubmit, save, passedChildren, dirty, closeForm, submitting, className, error, form, removeInnerSaveButton,
      canSaveUnchanged, SaveButtonComponent, CloseButtonComponent, ButtonsWrapper, formNoteText, submitSucceeded,
      errorsOnTop, invalid, showErrorNextSaveButton, submitFailed, isMobileTablet, removeInnerButtonsWrapper, switchButtonsPlaces,
      innerButtonsWrapperClassName,
    } = this.props;
    const isServerError = error === SERVER_ERROR;
    const errorMessage = isServerError
      ? SERVER_ERROR_MESSAGE
      : error;

    const isSubmitDisabled = submitSucceeded || submitting || (!dirty && !canSaveUnchanged) || error;
    return (
      <Form
        className={className}
        onSubmit={handleSubmit(save)}
        name={form}
      >
        <SubmissionContext.Provider value={this.submissionContextProps}>
          {isMobileTablet && formNoteText && <NoteLegend>{formNoteText}</NoteLegend>}
          {errorsOnTop && error && <ErrorBox error={errorMessage} rounded className="mb-7" />}
          {passedChildren}
          {!errorsOnTop && error && <ErrorBox error={typeof errorMessage === 'string' ? errorMessage.charAt(0).toUpperCase() + errorMessage.substring(1) : errorMessage} rounded className="my-7" />}
          {removeInnerSaveButton
            ? null
            : (
              <ButtonsWrapper className="mb-5 mb-md-0">
                <InnerButtonsWrapper removeInnerButtonsWrapper={removeInnerButtonsWrapper} innerButtonsWrapperClassName={innerButtonsWrapperClassName}>
                  {!switchButtonsPlaces && <SaveButtonComponent disabled={isSubmitDisabled} submitting={submitting} invalid={invalid} />}
                  {closeForm &&
                    <CloseButtonComponent onClick={closeForm} type="button" isSubmitDisabled={isSubmitDisabled} />}
                  {switchButtonsPlaces && <SaveButtonComponent disabled={isSubmitDisabled} submitting={submitting} invalid={invalid} />}
                </InnerButtonsWrapper>
                {showErrorNextSaveButton && invalid && submitFailed && !isServerError &&
                  <ErrorBox
                    rounded
                    className="mb-5 py-3"
                    error="Make sure all the required fields (*) are filled and errors are resolved."
                  />}
              </ButtonsWrapper>
            )}
          {!isMobileTablet && formNoteText && <NoteLegend>{formNoteText}</NoteLegend>}
        </SubmissionContext.Provider>
      </Form>
    );
  }
}

FormWrapper.propTypes = {
  className: PropTypes.string,
  passedChildren: PropTypes.any,
  closeForm: PropTypes.func,
  save: PropTypes.func,
  dirty: PropTypes.bool,
  errorsOnTop: PropTypes.bool,
  error: PropTypes.string,
  form: PropTypes.string,
  removeInnerSaveButton: PropTypes.bool,
  showErrorNextSaveButton: PropTypes.bool,
  invalid: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  handleSubmit: PropTypes.func,
  canSaveUnchanged: PropTypes.bool,
  SaveButtonComponent: PropTypes.any,
  CloseButtonComponent: PropTypes.any,
  ButtonsWrapper: PropTypes.any,
  formNoteText: PropTypes.string,
  isMobileTablet: PropTypes.bool,
  removeInnerButtonsWrapper: PropTypes.bool,
  switchButtonsPlaces: PropTypes.bool,
  innerButtonsWrapperClassName: PropTypes.string,
};

InnerButtonsWrapper.propTypes = {
  removeInnerButtonsWrapper: PropTypes.bool,
  children: PropTypes.any,
  innerButtonsWrapperClassName: PropTypes.string,
};

FormWrapper.defaultProps = {
  SaveButtonComponent: DefaultSaveButton,
  CloseButtonComponent: DefaultCloseButton,
  ButtonsWrapper: DefaultButtonsWrapper,
};

export default ResponsiveHoC(FormWrapper);
