import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';

import { toJS } from 'utils/general';
import { makeSubSelector, makeSubSelectorToJS } from 'utils/selectors';
import {
  generateFiltersSelectors, generateLoadedContentSelectors, generateFiltersUiSelectors,
} from 'utils/filter/selectors';
import { CLASS_TYPE, PERMISSION } from 'containers/App/constants';
import { makeSelectMyCompany, makeSelectProfile, makeSelectUserIs } from 'containers/AuthProvider/selectors';
import { flattenJAPIObject } from 'utils/jsonApiExtract';

import { PEOPLE_PAGE_IDENTIFIER, RESULTS_TO_ADD_PER_ROUND } from './constants';
import reducer from './reducer';
import { makeSelectObjectFromRefSelector, selectIncluded } from '../../App/selectors';
import { ADMIN_ROLES, COMMON_ROLES } from './Filters/constants';

const localInitialState = reducer(undefined, {});

const selectPeoplePage = (state) => state ? state.get(PEOPLE_PAGE_IDENTIFIER, localInitialState) : localInitialState;

const {
  makeSelectLoading,
  makeSelectLoadingNextPage,
  makeSelectMarkedObjectsRefs: makeSelectMarkedPeopleRefs,
  makeSelectHasMore,
  makeSelectTotalCount,
  makeSelectObjects: makeSelectPeople,
  makeSelectSelectedObject: makeSelectSelectedPerson,
  makeSelectMarkedObjects: makeSelectMarkedPeople,
} = generateLoadedContentSelectors(PEOPLE_PAGE_IDENTIFIER);

const {
  makeSelectFilter,
  makeSelectMaxPage,
  selectTmpAdvFiltersOperator,
  selectAdvFiltersOperator,
  selectSearchOperator,
  selectTmpSearchOperator,
  makeSelectSearch,
  makeSelectAppliedFilters,
  makeSelectAdvancedFilters,
  makeSelectTempFilters,
  makeSelectAdvFiltersForKey,
  makeSelectTempFiltersForKey,
  makeSelectTempFiltersForGroup,
  makeSelectAdvFiltersForGroup,
  makeSelectSelectedCategory,
  makeSelectSort,
  selectSortObj,
  makeSelectIndividualOptionsOperators,
  makeSelectFilterGroupsOperators,
} = generateFiltersSelectors(PEOPLE_PAGE_IDENTIFIER);

const {
  makeSelectIsAdvFiltersOpen,
  makeSelectIsSectionOpen,
  makeSelectSelectedAdvFilter,
  makeSelectIsSearchFocused,
} = generateFiltersUiSelectors(PEOPLE_PAGE_IDENTIFIER);


const makeSelectIsInviteCompanyOpen = () => makeSubSelector(selectPeoplePage, ['peopleUi', 'isInviteCompanyOpen']);
const makeSelectIsInviteClassCoachOpen = () => makeSubSelector(selectPeoplePage, ['peopleUi', 'isInviteClassCoachOpen']);
const selectMobileFiltersModalOpen = makeSubSelector(selectPeoplePage, ['peopleUi', 'mobileFiltersModalIsOpen']);

const selectPeopleUi = makeSubSelector(selectPeoplePage, ['peopleUi']);
const selectOpenAccordionItems = () => createSelector(
  selectPeopleUi,
  (peopleUiState) => toJS(peopleUiState.get('openAccordionItems')),
);

const selectPeopleListsModal = makeSubSelector(selectPeoplePage, ['peopleListsModal']);
const makeSelectMarkedPeopleLists = () => createSelector(
  selectPeopleListsModal,
  (peopleListsModalState) => toJS(peopleListsModalState.get('selectedPeopleLists')),
);
const makeSelectPeopleListsModalIsOpen = () => makeSubSelector(selectPeopleListsModal, ['isPeopleListsOpen']);
const makeSelectIsAddToListError = () => makeSubSelector(selectPeopleListsModal, ['isAddToListError']);

const selectPeopleListsIsAddSearchResults = makeSubSelector(selectPeopleListsModal, ['isAddSearchResults']);
const selectPeopleListsIsSending = makeSubSelector(selectPeopleListsModal, ['isSending']);
const selectPeopleListsSearchResultsOffset = makeSubSelector(selectPeopleListsModal, ['searchResultsOffset']);
const selectPeopleListsSearchResultsToAdd = createSelector(
  selectPeopleListsSearchResultsOffset,
  makeSelectTotalCount(),
  (offset, totalCount) => Math.min(RESULTS_TO_ADD_PER_ROUND, totalCount - offset)
);

const selectMergeModal = makeSubSelector(selectPeoplePage, ['mergeModal']);
const selectMergeModalIsOpen = makeSubSelector(selectMergeModal, ['isOpen']);
const selectMergeTargetRef = makeSubSelector(selectMergeModal, ['mergeTarget']);
const selectMergeTarget = makeSelectObjectFromRefSelector(selectMergeTargetRef);
const selectIsMerging = makeSubSelector(selectMergeModal, ['isMerging']);
const selectConnections = makeSubSelectorToJS(selectIncluded, ['connections']);

const selectRawRecsysFeedback = makeSubSelectorToJS(selectIncluded, ['recsys_feedback']);
const selectRecsysFeedback = createSelector(
  selectRawRecsysFeedback,
  (rawRecsysFeedback) => rawRecsysFeedback && Object.values(rawRecsysFeedback).map((r) => flattenJAPIObject(r))
);

const selectPeopleFiltersExpanded = makeSubSelector(selectPeoplePage, ['peopleUi', 'filtersExpanded']);
const selectIsAxFounderOnly = createSelector(
  makeSelectUserIs(PERMISSION.founder),
  makeSelectMyCompany(),
  (isFounder, founderCompany) => isFounder && founderCompany?.aclasses_rel?.().every((aRel) => aRel.class_type === CLASS_TYPE.alchemistx)
);
const selectIsAxCCoachOnly = createSelector(
  makeSelectUserIs(PERMISSION.class_coach),
  makeSelectProfile(),
  (isClassCoach, myProfile) => isClassCoach && myProfile?.coach_classes?.().every((c) => c.class_type === CLASS_TYPE.alchemistx)
);

const selectRolesCategories = createSelector(
  makeSelectUserIs(PERMISSION.admin),
  selectIsAxFounderOnly,
  selectIsAxCCoachOnly,
  (iAmAdmin, isAxFounderOnly, isAxCCoachOnly) => {
    const allRoles = cloneDeep(COMMON_ROLES);
    if (iAmAdmin) {
      Object.assign(allRoles, ADMIN_ROLES);
      return allRoles;
    }
    if (isAxFounderOnly || isAxCCoachOnly) delete allRoles.faculty;
    if (isAxFounderOnly) delete allRoles.alumni_mentor;
    return allRoles;
  }
);

const makeSelectQsForExperiencesSearch = () => makeSubSelector(selectPeoplePage, ['peopleUi', 'qsForExperiencesSearch']);

export {
  makeSelectPeople,
  makeSelectFilter,
  makeSelectAppliedFilters,
  makeSelectSearch,
  makeSelectSelectedCategory,
  makeSelectAdvancedFilters,
  makeSelectTempFilters,
  makeSelectSelectedPerson,
  makeSelectMarkedPeopleRefs,
  makeSelectMarkedPeople,
  makeSelectHasMore,
  makeSelectTotalCount,
  makeSelectLoading,
  makeSelectLoadingNextPage,
  makeSelectSort,
  selectSortObj,
  makeSelectMaxPage,
  makeSelectIsAdvFiltersOpen,
  makeSelectIsSectionOpen,
  makeSelectSelectedAdvFilter,
  makeSelectIsSearchFocused,
  makeSelectIsInviteCompanyOpen,
  makeSelectMarkedPeopleLists,
  makeSelectPeopleListsModalIsOpen,
  makeSelectIsInviteClassCoachOpen,
  selectPeopleListsIsSending,
  selectPeopleListsIsAddSearchResults,
  selectPeopleListsSearchResultsOffset,
  selectPeopleListsSearchResultsToAdd,
  selectPeoplePage,
  selectTmpAdvFiltersOperator,
  selectAdvFiltersOperator,
  makeSelectAdvFiltersForKey,
  makeSelectTempFiltersForKey,
  selectMergeModalIsOpen,
  selectMergeTarget,
  selectMergeTargetRef,
  selectIsMerging,
  selectSearchOperator,
  selectTmpSearchOperator,
  makeSelectTempFiltersForGroup,
  makeSelectAdvFiltersForGroup,
  selectMobileFiltersModalOpen,
  selectOpenAccordionItems,
  selectConnections,
  selectPeopleFiltersExpanded,
  makeSelectIndividualOptionsOperators,
  makeSelectFilterGroupsOperators,
  selectIsAxFounderOnly,
  selectRecsysFeedback,
  selectRolesCategories,
  makeSelectIsAddToListError,
  makeSelectQsForExperiencesSearch,
};
