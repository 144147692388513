/*
 * People List Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */


import { SET_SELECTED_PERSON, SELECTED_PERSON_NOT_FOUND, SELECTED_PERSON_CONNECTIONS_FETCH, SELECTED_PERSON_CONNECTIONS_SUCCESS, SELECTED_PERSON_CONNECTIONS_FAIL, SET_SIMILAR_PROFILES, SET_LP_FUNDS, SET_FORM_LP_FUNDS_ERR } from './constants';

// export const saveFormSection = createFormAction(SAVE_FORM_SECTION);
const setSelectedPerson = (selectedPerson) => ({ type: SET_SELECTED_PERSON, selectedPerson });
const selectedPersonNotFound = () => ({ type: SELECTED_PERSON_NOT_FOUND });
const selectedPersonConnectionsFetch = () => ({ type: SELECTED_PERSON_CONNECTIONS_FETCH });
const selectedPersonConnectionsSuccess = () => ({ type: SELECTED_PERSON_CONNECTIONS_SUCCESS });
const selectedPersonConnectionsFail = () => ({ type: SELECTED_PERSON_CONNECTIONS_FAIL });
const setSimilarProfiles = (similarProfiles) => ({ type: SET_SIMILAR_PROFILES, similarProfiles });

const setLpFunds = (lpFunds) => ({ type: SET_LP_FUNDS, lpFunds });
const setFormDesignatedFundsErr = (formLpFundError) => ({ type: SET_FORM_LP_FUNDS_ERR, formLpFundError });

export { setSelectedPerson, selectedPersonNotFound, selectedPersonConnectionsFetch, selectedPersonConnectionsSuccess, selectedPersonConnectionsFail, setSimilarProfiles, setLpFunds, setFormDesignatedFundsErr };
