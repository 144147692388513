import PropTypes from 'prop-types';
import { parse } from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import OnePager from 'components/OnePager/OnePager';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { ButtonPrim } from 'components/Buttons/ButtonPrim';

import { SAVE_INTEREST } from './constants';
import reducer from './reducer';
import saga from './saga';
import { makeSelectInvitation } from './selectors';


class SneakPeekConditionsDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInterested: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    this.invitationId = queryParams.invitation;
    this.userRef = queryParams.ref;
  }

  render() {
    const to = this.props?.invitation?.user?.();

    if (this.state.isInterested === null) {
      return (
        <OnePager title="Would you like to get an exclusive preview of the startups ahead of demo day?">
          <ButtonPrim
            onClick={() => {
              this.props.saveInterestInfo(this.userRef, this.invitationId);
              this.setState({ isInterested: false });
            }}
          >No, Thank You
          </ButtonPrim>
        </OnePager>
      );
    }

    return (
      <OnePager title={`Thanks${to ? `, ${to.firstname}` : ''}!`}>
        <p>We received your response and will make note for future requests.</p>

        <a className="dsa-link-button link" href="http://alchemistaccelerator.com/portfolio/">
          Check out the current Alchemist Portfolio
        </a>
      </OnePager>
    );
  }
}

SneakPeekConditionsDecline.propTypes = {
  location: PropTypes.any,
  invitation: PropTypes.any,
  saveInterestInfo: PropTypes.any,
};


export function mapDispatchToProps(dispatch) {
  return {
    saveInterestInfo: (userRef, invitationId) => dispatch({
      type: SAVE_INTEREST, userRef, invitationId, status: false,
    }),
  };
}

const mapStateToProps = createStructuredSelector({
  invitation: makeSelectInvitation(),
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'sneakPeekConditions', reducer });
const withSaga = injectSaga({ key: 'sneakPeekConditions', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(SneakPeekConditionsDecline);
