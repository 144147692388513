import mapValues from 'lodash/mapValues';
import { stringify } from 'qs';

import {
  API_IFS_MEETS_BASE_URL,
  CAPABILITIES,
  CLASS_TYPE,
  RESOURCE_ALCHEMIST_CLASSES,
  RESOURCE_COMPANIES,
  RESOURCE_IFS_PARTICIPANT_SETS,
  RESOURCE_INVESTMENT_ROUNDS,
  RESOURCE_PEOPLE,
} from 'containers/App/constants';
import { nonSagaRequest as request } from 'utils/request';

import { flattenJAPIObject } from './jsonApiExtract';
import { OR_OP } from './filter/constants';

export const getResourceUrl = (resource, queryParams, extraQueryParams = '') => `/api/v1/${resource}?${stringify(queryParams)}${extraQueryParams ? `&${extraQueryParams}` : ''}`;

export const getAutoCompleteResFilter = (
  resource,
  field = 'text',
  {
    searchOp = 'search', extraFilters, pageSize = 10, canCreate, extraFields = [], sort: sortConf, minimum,
    exactMatchSearchField, ignoreFieldWhenEmpty = false, fop, sortConfigForOptions, extraQueryParams = '',
  } = {}
) => async function fetch(input, loadedOptions) {
  if (minimum && (!input || input.length < minimum)) {
    return { options: [], hasMore: false };
  }
  let exactMatchJsonApi;
  if (exactMatchSearchField && input && !(loadedOptions && loadedOptions.length)) {
    exactMatchJsonApi = await request(getResourceUrl(resource, {
      filter: { ...extraFilters, [`${exactMatchSearchField}:eq`]: input },
      fields: { [resource]: [field, ...(extraFields)].join() },
    }));
  }
  const sort = sortConf === 'similar' ? `-similar[${field}]=${input?.replace(/[,=]/g, ' ') || ''}` : sortConf;

  const filters = { ...extraFilters };
  if (!(ignoreFieldWhenEmpty && !input)) {
    filters[`${field}:${searchOp}`] = (input || '');
  }
  const jsonApi = await request(getResourceUrl(resource, {
    filter: filters,
    fields: { [resource]: [field, ...(extraFields)].join() },
    page: getPageQuery(loadedOptions, pageSize),
    sort,
    fop,
  }, extraQueryParams));

  return canCreate && input && input.trim() && (!loadedOptions || !loadedOptions.length)
    ? generateOptionsFromJsonApiWithNew(jsonApi, input, field, resource, exactMatchJsonApi)
    : generateOptionsFromJsonApi(jsonApi, sortConfigForOptions);
};

const generateOptionsFromJsonApi = (jsonApi, sortConfigForOptions) => {
  const returnObj = {
    options: jsonApi.data && jsonApi.data.map((opt) => flattenJAPIObject(opt)),
    hasMore: !!jsonApi.links.next,
  };

  if (sortConfigForOptions?.sortArray && sortConfigForOptions?.sortProp) {
    const orderedOptions = sortByOrder(returnObj.options, sortConfigForOptions.sortArray, sortConfigForOptions.sortProp);
    // sorts options using sortConfigForOptions.sortProp by the order of sortConfigForOptions.sortArray
    returnObj.options = orderedOptions;
  }
  return returnObj;
};

const generateOptionsFromJsonApiWithNew = (jsonApi, input, field, resource, exactMatchJsonApi) => {
  const result = generateOptionsFromJsonApi(jsonApi);
  const objects = generateOptionsFromJsonApi(jsonApi).options;
  if (exactMatchJsonApi && exactMatchJsonApi.data && exactMatchJsonApi.data.length) {
    const exactMatch = generateOptionsFromJsonApi(exactMatchJsonApi).options[0];
    return { ...result, options: [exactMatch, ...objects.filter((obj) => obj.name !== exactMatch.name)] };
  }
  return !objects.find((obj) => obj[field] && String(obj[field]).toLowerCase() === input.trim().toLowerCase())
    ? { ...result, options: [makeNewObject(input.trim(), field, resource), ...objects] }
    : result;
};

export const makeNewObject = (text, field, type) => ({
  [field]: text, type, id: -(new Date()).getTime(), isNew: true,
});

const sortByOrder = (objects, sortArray, sortProp) => {
  if (objects && sortArray && sortProp) {
    return sortArray.map((o) => objects.find((obj) => obj[sortProp] === o)).filter((o) => o);
  }
  return [];
};

// ToDO: try to fix the minimum with React Select configuration (maybe on v2?)
export const getTagsAutocompleteBase = (allowNew) => getAutoCompleteResFilter(
  'tags',
  'text',
  {
    minimum: 2, searchOp: 'similar', sort: 'similar', canCreate: allowNew,
  }
);
export const getPerkCategoriesAutocompleteBase = (allowNew = true) => getAutoCompleteResFilter(
  'perk_categories',
  'text',
  {
    minimum: 2, searchOp: 'similar', sort: 'similar', canCreate: allowNew,
  }
);
export const getCompanyAutocomplete = getAutoCompleteResFilter(RESOURCE_COMPANIES, 'name');
export const getCompanyAutocompleteAllowNew = getAutoCompleteResFilter(
  RESOURCE_COMPANIES,
  'name',
  { canCreate: true, exactMatchSearchField: 'processed_name' }
);

export function getCompanyAutocompleteWithMembersRel(input, loadedOptions) {
  // eslint-disable-next-line no-param-reassign
  input = input?.trim();
  if ((!input || input.length < 2)) return { options: [], hasMore: false };
  const field = 'name';
  const pageSize = 10;
  const url = getResourceUrl(RESOURCE_COMPANIES, {
    filter: { [`${field}:search`]: input || '' },
    page: getPageQuery(loadedOptions, pageSize),
    include: 'current_members_rel.user,aclass.class_type,aclasses_rel,aclasses_rel.aclass',
    fields: { [RESOURCE_COMPANIES]: `${field},current_members_rel,aclass,aclasses_rel,is_alchemist,status,logo` },
  });
  return request(url).then((jsonApi) => ({
    options: flattenJAPIObjectListWithRelsAndNew({ jsonApi, input, field, resource: RESOURCE_COMPANIES, recursiveFlatten: true, allowNewAlways: true }),
    hasMore: !!jsonApi.links.next,
  }));
}

export const getPersonAutocomplete = getAutoCompleteResFilter(RESOURCE_PEOPLE, 'nicename');
export const getPersonAutocompleteWithPosition = getAutoCompleteResFilter(RESOURCE_PEOPLE, 'nicename', { extraFields: ['current_position'] });

export const getPersonAutocompleteWithPositionOnlyFounders = getAutoCompleteResFilter(
  RESOURCE_PEOPLE,
  'nicename',
  {
    extraFilters: { 'account.capabilities_rel.capability:eq': CAPABILITIES.founder },
    extraFields: ['current_position'],
  }
);

export function getEmailPersonAutocompleteWithCompanyInfo({ input, loadedOptions, field = 'email', debounce = 500, fieldForCreateNew }) {
  let timeout;
  // eslint-disable-next-line no-param-reassign
  input = input?.trim();
  if ((!input || input.length < 2)) return { options: [], hasMore: false };
  const pageSize = 10;
  const url = getResourceUrl(RESOURCE_PEOPLE, {
    filter: { [`${field}:search`]: input || '' },
    page: getPageQuery(loadedOptions, pageSize),
    include: 'roles,company,company_membership,account',
    fields: { [RESOURCE_PEOPLE]: 'email,nicename,firstname,lastname,roles,company,profileImg,company.current_members_rel,account,account.id' },
  });

  const doFetch = () => request(url)
    .then((jsonApi) => ({
      options: flattenJAPIObjectListWithRelsAndNew(
        { jsonApi, input, field, resource: RESOURCE_PEOPLE, recursiveFlatten: true, allowNewAlways: field !== 'email', fieldForCreateNew }
      ),
      hasMore: !!jsonApi.links.next,
    }));

  return new Promise((resolve, reject) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => doFetch().then(resolve).catch(reject), debounce);
  });
}

export const getFirstnamePersonAutocompleteWithCompanyInfo = (input, loadedOptions) => getEmailPersonAutocompleteWithCompanyInfo(
  { input, loadedOptions, field: 'nicename', fieldForCreateNew: 'firstname' }
);
export const getLastnamePersonAutocompleteWithCompanyInfo = (input, loadedOptions) => getEmailPersonAutocompleteWithCompanyInfo(
  { input, loadedOptions, field: 'nicename', fieldForCreateNew: 'lastname' }
);

export const getPersonAutocompleteAllowNew = getAutoCompleteResFilter(RESOURCE_PEOPLE, 'nicename', { canCreate: true, extraFields: ['profileimg', 'current_position'] });
export const getAlchemistClassAutocompleteWithFilters = (extraFilters, classType = CLASS_TYPE.alchemist, extraFields = []) => getAutoCompleteResFilter(
  RESOURCE_ALCHEMIST_CLASSES,
  'title_or_number',
  {
    extraFilters: classType ? { 'class_type:eq': classType, ...extraFilters } : extraFilters,
    extraFields: ['number', 'title', ...extraFields],
    pageSize: 0,
    sort: '-id',
  }
);
export const getAlchemistClassAutocomplete = getAlchemistClassAutocompleteWithFilters({ 'number:ne': 0 });
export const getPopulatedAlchemistClassAutocomplete = getAlchemistClassAutocompleteWithFilters({
  'number:ne': 0,
  'companies:any': '',
});
export const getPopulatedAlchemistXClassAutocomplete = getAlchemistClassAutocompleteWithFilters({ 'number:ne': 0, 'companies:any': '' }, CLASS_TYPE.alchemistx);
export const getRegistrantsAutocompleteBase = (resource, extraFilters) => getAutoCompleteResFilter(
  resource,
  'nicename',
  {
    extraFilters,
    sort: '-id',
  }
);

export const getRegistrantCompaniesAutocompleteBase = (resource, extraFilters) => getAutoCompleteResFilter(
  resource,
  'name',
  {
    extraFilters,
    sort: '-id',
  }
);

export const getEligibleInvestors = ({
  meetId, excludedIds = [], slot, founderInvitationId,
}) => async function fetch(input, loadedOptions) {
  const pageSize = 10;
  const queryParams = {
    filter: { 'nicename:search': input || '' },
    page: getPageQuery(loadedOptions, pageSize),
    fields: { [RESOURCE_PEOPLE]: 'nicename' },
    sort: '-id',
    _excluded_ids: `[${excludedIds.join(',')}]` || '[]',
  };
  const finalQueryParams = slot && founderInvitationId ? {
    _slot: slot,
    _founder_invitation: founderInvitationId,
    ...queryParams,
  } : queryParams;
  const url = slot && founderInvitationId ? '' : `/${meetId}`;

  const jsonApi = await request(`${API_IFS_MEETS_BASE_URL}${url}/eligible_investors?${stringify(finalQueryParams)}`);
  return generateOptionsFromJsonApi(jsonApi);
};

export const getEligibleIfsFounders = ({
  meetId, excludedIds = [], slot, investorInvitationId,
}) => async function fetch(input, loadedOptions) {
  const pageSize = 10;
  const queryParams = {
    filter: { 'nicename:search': input || '' },
    page: getPageQuery(loadedOptions, pageSize),
    fields: { [RESOURCE_PEOPLE]: 'nicename' },
    sort: '-id',
    _excluded_ids: `[${excludedIds.join(',')}]` || '[]',
  };
  const finalQueryParams = slot && investorInvitationId ? {
    _slot: slot,
    _investor_invitation: investorInvitationId,
    ...queryParams,
  } : queryParams;
  const url = slot && investorInvitationId ? '' : `/${meetId}`;

  const jsonApi = await request(`${API_IFS_MEETS_BASE_URL}${url}/eligible_founders?${stringify(finalQueryParams)}`);
  return generateOptionsFromJsonApi(jsonApi);
};

export const getParticipantSets = (ifsId) => getAutoCompleteResFilter(
  RESOURCE_IFS_PARTICIPANT_SETS,
  'participant_type',
  {
    extraFilters: {
      'ifs:eq': `{"type":"ifs","id":${ifsId}}`,
      '_1:base_user_list:has': '',
      '_1:participant_type:in': JSON.stringify(['floaters_founder', 'floaters_investor']),
    },
    fop: { '_0:and:_1': '', _1: OR_OP },
    pageSize: 0,
    sort: '-id',
    searchOp: 'eq',
    ignoreFieldWhenEmpty: true,
  },
);

const getPageQuery = (loadedOptions, pageSize) => ({
  size: pageSize,
  number: loadedOptions && pageSize ? Math.floor(loadedOptions.length / pageSize) + 1 : 1,
});

export const getTagsAutocompleteWithSuggestions = (
  suggestions = [],
  pageSize = 10,
  canCreate,
) => (input, loadedOptions) => {
  const baseFilter = input ? { 'text:search': `${input}` } : { 'text:in': JSON.stringify(suggestions) };

  return request(getResourceUrl('tags', {
    filter: { ...baseFilter },
    page: getPageQuery(loadedOptions, pageSize),
    fields: { tags: 'text' },
  })).then((jsonApi) => {
    if (!input || !input.trim()) {
      return {
        options: [{ label: 'Suggestions', options: generateOptionsFromJsonApi(jsonApi).options }],
        hasMore: !!jsonApi.links.next,
      };
    }
    if (canCreate) {
      return generateOptionsFromJsonApiWithNew(jsonApi, input, 'text', 'tags');
    }
    return generateOptionsFromJsonApi(jsonApi);
  });
};

export function getFieldAutocomplete(resource, field, sort = '', limit = 5, debounce = 300, extraQueryParams = '', isAsyncCreatable = false) {
  let timeout;
  const queryParams = `${sort && `&sort=${sort}`}${limit && limit !== 0 ? `&limit=${limit}` : ''}${extraQueryParams && `&${extraQueryParams}`}`;

  return (input, loadedOptions = []) => {
    const doFetch = () => request(`/api/v1/${resource}/autocomplete/${field}?q=${input}&offset=${loadedOptions.length}${queryParams}`)
      .then((jsonApi) => {
        if (isAsyncCreatable) {
          // https://github.com/JedWatson/react-select/issues/2725
          return jsonApi.meta.autocomplete;
        }
        return {
          options: jsonApi.meta.autocomplete,
          hasMore: jsonApi.meta.autocomplete.length === limit,
        };
      });

    return new Promise((resolve, reject) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => doFetch().then(resolve).catch(reject), debounce);
    });
  };
}

// This also searches either name or email
export function getPersonWithCompanyAutocomplete(input, loadedOptions) {
  const pageSize = 10;
  const url = getResourceUrl(RESOURCE_PEOPLE, {
    filter: {
      'nicename:search': input || '',
      ...(input.includes(' ') ? {} : { '_2:email:search': input || '' }),
    },
    ...(input.includes(' ') ? {} : { fop: { '_1:or:_2': OR_OP } }),
    page: getPageQuery(loadedOptions, pageSize),
    include: 'company',
    fields: { [RESOURCE_COMPANIES]: 'name', [RESOURCE_PEOPLE]: 'nicename,profileimg,email,company' },
  });

  return request(url).then((jsonApi) => ({ options: flattenJAPIObjectListWithRels(jsonApi), hasMore: !!jsonApi.links.next }));
}

export function getCompanyWithInvestmentRoundsAutocomplete(input, loadedOptions) {
  const pageSize = 10;
  const url = getResourceUrl(RESOURCE_COMPANIES, {
    filter: { 'is_alchemist:eq': true, 'name:search': input || '', 'onboarded:eq': true },
    page: getPageQuery(loadedOptions, pageSize),
    include: 'investment_rounds',
    fields: { [RESOURCE_COMPANIES]: 'name,investment_rounds', [RESOURCE_INVESTMENT_ROUNDS]: 'stage' },
  });

  return request(url).then((jsonApi) => ({ options: flattenJAPIObjectListWithRels(jsonApi), hasMore: !!jsonApi.links.next }));
}

const flattenJAPIObjectListWithRels = (jsonApi) => jsonApi.data && jsonApi.data.map((item) => ({
  ...flattenJAPIObject(item),
  ...mapValues(
    item.relationships,
    (relField) => () => Array.isArray(relField.data)
      ? relField.data.map(findRefInIncluded(jsonApi.included)).map((obj) => flattenJAPIObject(obj))
      : flattenJAPIObject(findRefInIncluded(jsonApi.included)(relField.data))
  ),
}));

const recursivelyFlattenJAPIObjectListWithRels = (jsonApi) => {
  const processObj = (item) => {
    const { included } = jsonApi;
    return {
      ...flattenJAPIObject(item),
      ...mapValues(
        item.relationships,
        (relField) => () => {
          const { data: relData } = relField;
          if (Array.isArray(relData)) return relData.map(findRefInIncluded(included)).map((obj) => obj.relationships ? processObj(obj) : flattenJAPIObject(obj));
          const includedObj = findRefInIncluded(included)(relData);
          return includedObj?.relationships ? processObj(includedObj) : flattenJAPIObject(includedObj);
        }
      ),
    };
  };
  return jsonApi.data && jsonApi.data.map(processObj);
};

const flattenJAPIObjectListWithRelsAndNew = ({ jsonApi, input, field, resource, exactMatchJsonApi, recursiveFlatten, allowNewAlways, fieldForCreateNew }) => {
  // flatten the data from jsonApi
  let flattenedObjects = recursiveFlatten
    ? recursivelyFlattenJAPIObjectListWithRels(jsonApi)
    : flattenJAPIObjectListWithRels(jsonApi);

  // if there is an exact match in the exactMatchJsonApi, handle it
  if (exactMatchJsonApi && exactMatchJsonApi.data && exactMatchJsonApi.data.length) {
    const exactMatch = flattenJAPIObjectListWithRels(exactMatchJsonApi)[0];
    flattenedObjects = [
      exactMatch,
      ...flattenedObjects.filter((obj) => obj[field] !== exactMatch[field]),
    ];
  }

  // check if there is no existing object matching the input
  const inputLowered = input.trim().toLowerCase();
  const matchExists = flattenedObjects.some((obj) => obj[field] && String(obj[field]).toLowerCase() === inputLowered);

  const isFirstPage = !jsonApi.links.prev;

  // if no match, create a new object and prepend it to the options
  if (
    ((input && !matchExists && !allowNewAlways) || (input && allowNewAlways))
    && isFirstPage // only prepend once
  ) {
    const newObject = makeNewObject(input.trim(), fieldForCreateNew || field, resource);
    flattenedObjects = [newObject, ...flattenedObjects];
  }

  return flattenedObjects;
};

const findRefInIncluded = (included) => (ref) => included?.find((obj) => obj.id === ref?.id && obj.type === ref.type);

export const processErrorMsg = (errorMsg) => ({ 'should be string': 'Required' }[errorMsg] || errorMsg);

/**
* extracts and returns the text between angle brackets in a given string.
* @param {string} str - The input string.
* @returns {string} - The text between angle brackets.
*/
export function extractTextBetweenAngleBrackets(str) {
  const regex = /<(.*?)>/;
  const match = str?.toString?.().match(regex);

  if (match && match.length >= 2) {
    return match[1];
  }

  return '';
}
