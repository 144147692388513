import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody } from 'reactstrap';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import classNames from 'classnames';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import CloseButtonWrapper from 'components/Buttons/CloseButtonWrapper';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import { Text } from 'components/Text/Body';
import { makeSelectAccount, makeSelectMyCompany } from 'containers/AuthProvider/selectors';
import NameAvatar from 'components/Avatars/NameAvatar';
import { ButtonSec } from 'components/Buttons/ButtonSec';
import ListCol from 'components/BasicList/ListCol';
import { ButtonPrim } from 'components/Buttons/ButtonPrim';
import ModalSimple from 'components/Modals/ModalSimple';
import { CLASS_TYPE } from 'containers/App/constants';
import AlchLogoMark from 'containers/Companies/Search/AlchLogoMark';
import ListColTwoLines from 'components/BasicList/ListColTwoLines';
import ListColBase from 'components/BasicList/ListColBase';
import TooltipPrim from 'components/Tooltips/TooltipPrim';

import reducer from './reducer';
import saga from './saga';
import { setCompanyAndClass, setFounderChooseModal } from './actions';
import { FOUNDER_CHOOSE_MODAL } from './constants';
import { selectIsModalOpen, selectOrderedCompanyClasses } from './selectors';
import { getCapability } from './utils';


const FounderChooseModal = ({
  company, setIsModalOpen, isModalOpen, setStartupAndClass, myAccount, isDesktop, isTablet, orderedCompanyClasses,
}) => (
  <ModalSimple
    className="dsa-founder-choose-modal"
    size="lg"
    isOpen={isModalOpen}
    toggle={() => setIsModalOpen(false)}
  >
    <div className="text-center pb-5">
      <h1 className="font-weight-semibold mb-3">Choose a Startup{isDesktop || isTablet ? <>&nbsp;</> : <br />}and Class for Vault use</h1>
      <Text>The Vault platform will tailor all activities to the selected startup and class. Once logged in, startup and class selection can be changed through the &apos;My Account&apos; menu.</Text>
    </div>

    <CloseButtonWrapper closeAction={() => setIsModalOpen(false)} />
    <ModalBody className="p-0">
      {orderedCompanyClasses?.map((aRel) => {
        let status = company?.status;
        const isConsideredActive = aRel?.aclass?.().considered_active;
        const isFutureDemoDay = new Date() < new Date(aRel?.aclass?.().demo_date_pst_aware);
        const isAlchemist = aRel?.class_type === CLASS_TYPE.alchemist;
        const capability = getCapability(aRel?.aclass?.().id, myAccount);
        const isOnboarding = !capability?.onboarding_end_date;
        if (status === 'Active') {
          if (isConsideredActive
                || isFutureDemoDay
                || isAlchemist
          ) {
            status = 'Active';
          } else {
            status = 'Alum';
          }
        }
        const Wrapper = isDesktop ? React.Fragment : 'div';
        const wrapperProps = isDesktop ? {} : { className: 'dsa-company-class-details' };
        const ClassNameWrapper = aRel?.aclass?.().isAlchemistX ? ListColTwoLines : ListCol;
        return (
          <div className={classNames('d-flex align-items-center', { 'mb-5': isDesktop })}>
            <Wrapper {...wrapperProps}>
              <ListCol width={isDesktop ? 18 : ''}>
                <NameAvatar avatarClassName={isDesktop ? '' : 'me-0 mr-5'} profile={company} />
              </ListCol>
              {isDesktop &&
                <ListColBase>
                  <div className="d-flex align-items-center">
                    <div className="me-5"><AlchLogoMark isAlchemist={!aRel?.aclass?.().isAlchemistX} /></div>
                    <TooltipPrim tooltipClassName="list-col">
                      {aRel?.aclass?.().isAlchemistX &&
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {aRel?.aclass?.().program_name
                          ? `Demo Day for ${aRel?.aclass?.().program_name}`
                          : `Class ${aRel?.aclass?.().title_or_number}`
                        }
                      </>
                      }
                      {!aRel?.aclass?.().isAlchemistX && `${aRel?.aclass?.().titleOrRomanTitle()}`}
                    </TooltipPrim>
                  </div>
                </ListColBase>
              }
              <ListCol width={isDesktop ? 8 : ''} className={isDesktop ? 'text-center' : ''}>
                <div>{status}</div>
              </ListCol>
              {!isDesktop &&
                <ClassNameWrapper>
                  <div className="d-flex align-items-center">
                    <div className="me-5"><AlchLogoMark isAlchemist={!aRel?.aclass?.().isAlchemistX} /></div>
                    <div>
                      {aRel?.aclass?.().isAlchemistX &&
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {aRel?.aclass?.().program_name
                            ? `Demo Day for ${aRel?.aclass?.().program_name}`
                            : `Class ${aRel?.aclass?.().title_or_number}`
                          }
                        </>
                      }
                      {!aRel?.aclass?.().isAlchemistX && `${aRel?.aclass?.().titleOrRomanTitle()}`}
                    </div>
                  </div>
                </ClassNameWrapper>
              }
            </Wrapper>
            <ListCol width={isDesktop ? 10 : ''} className={classNames('dsa-action-btn', { 'text-center': isDesktop, 'text-right pr-5': !isDesktop })}>
              {(isConsideredActive || isFutureDemoDay) && isOnboarding &&
                <ButtonSec
                  className="mr-0"
                  onClick={() => setStartupAndClass(company?.id, aRel?.aclass?.().id, `/onboarding/${capability?.id}`)}
                >
                  Onboarding
                </ButtonSec>
              }
              {(
                (!isConsideredActive && !isFutureDemoDay && isAlchemist) // passed AA class
                    || (!isOnboarding && (isConsideredActive || isFutureDemoDay)) // active/future dd but done with onboarding
              ) &&
                <ButtonPrim className="mr-0" onClick={() => setStartupAndClass(company?.id, aRel?.aclass?.().id)}>
                  Select
                </ButtonPrim>
              }
            </ListCol>
          </div>
        );
      })}
    </ModalBody>
  </ModalSimple>
);

FounderChooseModal.propTypes = {
  company: PropTypes.object,
  setIsModalOpen: PropTypes.func,
  isModalOpen: PropTypes.bool,
  setStartupAndClass: PropTypes.func,
  myAccount: PropTypes.object,
  isDesktop: PropTypes.bool,
  isTablet: PropTypes.bool,
  orderedCompanyClasses: PropTypes.array,
};

const withReducer = injectReducer({ key: FOUNDER_CHOOSE_MODAL, reducer });
const withSaga = injectSaga({ key: FOUNDER_CHOOSE_MODAL, saga });

const mapStateToProps = createStructuredSelector({
  company: makeSelectMyCompany(),
  isModalOpen: selectIsModalOpen,
  myAccount: makeSelectAccount(),
  orderedCompanyClasses: selectOrderedCompanyClasses,
});

const mapDispatchToProps = (dispatch) => ({
  setIsModalOpen: (isModalOpen) => dispatch(setFounderChooseModal(isModalOpen)),
  setStartupAndClass: (companyId, aclassId, route) => dispatch(setCompanyAndClass(companyId, aclassId, route)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withReducer,
  withSaga,
  ResponsiveHoC,
  withConnect,
)(FounderChooseModal);
