import { call, put, select, takeLatest } from 'redux-saga/effects';

import { logError } from 'utils/log';
import { post } from 'utils/request';
import { API_GENERATE_TEMPLATE_URL } from 'containers/App/constants';
import { makeSelectLocation } from 'containers/App/selectors';

import { SEND_MESSAGE } from './constants';
import { selectChatHistory } from './selectors';
import { setChatHistory, setError, setIsLoading } from './actions';


function* sendMessage(action) {
  try {
    yield put(setIsLoading(true));
    const chatHistory = yield select(selectChatHistory);
    const location = yield select(makeSelectLocation());
    const matchedData = /(?<=\/generate\/)\d+/.exec(location.pathname);
    const templateReq = yield call(post, `${API_GENERATE_TEMPLATE_URL}/${matchedData?.[0]}`, {
      msg: action.msg,
      ...(chatHistory?.length > 0
        ? { msg_history: chatHistory }
        : {}
      ),
    });
    const { meta } = templateReq;

    if (chatHistory?.length === 0 && meta.chat_history) {
      yield put(setChatHistory(meta.chat_history));
    }
    if (meta.response) {
      yield put(setChatHistory([
        ...(chatHistory || []),
        ['assistant', meta.response],
      ]));
    }
  } catch (err) {
    logError(err);
    yield put(setError('An error occurred. Please try again later.'));
  } finally {
    yield put(setIsLoading(false));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  yield takeLatest(SEND_MESSAGE, sendMessage);
}
