/*
 * People List Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import { makeFormActionFunction } from 'utils/Forms/actions';

import {
  SAVE_FORM_SECTION,
  LOAD_FORM_SCHEMA,
  SET_FORM_SCHEMA,
  SAVE_PEOPLE_EXPERIENCE_SECTION,
  SAVE_PEOPLE_PORTFOLIO_SECTION,
  SAVE_COMPANY_INVESTORS_SECTION,
  SAVE_COMPANY_PORTFOLIO_SECTION,
  SAVE_COMPANY_INVESTMENT_ROUNDS_SECTION,
  SAVE_INVITE_COMPANY,
  SAVE_SHARED_COMPANIES_LIST,
  SEND_SHARED_COMPANIES_LIST,
  SAVE_SHARED_COMPANIES_LIST_DRAFT,
  PREVIEW_SHARED_COMPANIES_LIST,
  SEND_SHARED_COMPANIES_FOUNDER_INVITES,
  SAVE_SETTINGS,
  SET_USER_BY_EMAIL,
  SAVE_COMPANY_COACHES_SECTION,
  SET_COMPANY_BY_NAME,
  REGISTER_FIELD_POSITION,
  SEND_PASSWORD_CHANGE,
  FORM_SECTION_SAVED, SAVE_COMPANY_FOUNDERS_SECTION,
  SAVE_DEMODAY_ATTENDANCE_SECTION,
  SAVE_GEN_INFO_SECTION,
  SAVE_PEOPLE_ACCOUNT_SECTION,
  SAVE_MENTOR_SECTION,
  SAVE_FORM_WITH_EMAILS,
  SAVE_FORM_DESIGNATED_FUNDS,
} from './constants';

const loadFormSchema = (resourceName, name, extraParams) => ({
  type: LOAD_FORM_SCHEMA, resourceName, name, extraParams,
});
const setFormSchema = (schema, resourceName) => ({ type: SET_FORM_SCHEMA, schema, resourceName });
const registerFieldPosition = (form, field, position) => ({
  type: REGISTER_FIELD_POSITION, form, field, position,
});
const setUserByEmail = (email, user) => ({ type: SET_USER_BY_EMAIL, email, user });
const setCompanyByName = (name, company) => ({ type: SET_COMPANY_BY_NAME, name, company });

const formSectionSaved = (section, response, saveFormAction) => ({
  type: FORM_SECTION_SAVED, section, response, saveFormAction,
});
const saveFormSection = makeFormActionFunction(SAVE_FORM_SECTION);
const savePeopleExperienceSection = makeFormActionFunction(SAVE_PEOPLE_EXPERIENCE_SECTION);
const savePeoplePortfolioSection = makeFormActionFunction(SAVE_PEOPLE_PORTFOLIO_SECTION);
const saveCompanyInvestorsSection = makeFormActionFunction(SAVE_COMPANY_INVESTORS_SECTION);
const saveCompanyPortfolioSection = makeFormActionFunction(SAVE_COMPANY_PORTFOLIO_SECTION);
const saveCompanyInvestmentRoundsSection = makeFormActionFunction(SAVE_COMPANY_INVESTMENT_ROUNDS_SECTION);
const saveCompanyCoachesSection = makeFormActionFunction(SAVE_COMPANY_COACHES_SECTION);
const saveCompanyFoundersSection = makeFormActionFunction(SAVE_COMPANY_FOUNDERS_SECTION);
const saveInviteCompany = makeFormActionFunction(SAVE_INVITE_COMPANY);
const saveSettings = makeFormActionFunction(SAVE_SETTINGS);
const saveSharedCompaniesList = makeFormActionFunction(SAVE_SHARED_COMPANIES_LIST);
const sendSharedCompaniesFounderInvites = makeFormActionFunction(SEND_SHARED_COMPANIES_FOUNDER_INVITES);
const sendPasswordChange = makeFormActionFunction(SEND_PASSWORD_CHANGE);
const saveDemoDayAttendanceSection = makeFormActionFunction(SAVE_DEMODAY_ATTENDANCE_SECTION);
const saveGeneralInfoSection = makeFormActionFunction(SAVE_GEN_INFO_SECTION);
const savePeopleAccountSection = makeFormActionFunction(SAVE_PEOPLE_ACCOUNT_SECTION);
const saveMentorSection = makeFormActionFunction(SAVE_MENTOR_SECTION);
const saveFormSectionWithEmails = makeFormActionFunction(SAVE_FORM_WITH_EMAILS);
const saveFormDesignatedFunds = (values) => ({ type: SAVE_FORM_DESIGNATED_FUNDS, ...values });

const previewSharedCompaniesList = (section) => ({ type: PREVIEW_SHARED_COMPANIES_LIST, section });
const saveSharedCompaniesListDraft = (section) => ({ type: SAVE_SHARED_COMPANIES_LIST_DRAFT, section });
const sendSharedCompaniesList = (section) => ({ type: SEND_SHARED_COMPANIES_LIST, section });

export {
  loadFormSchema,
  setFormSchema,
  registerFieldPosition,
  setUserByEmail,
  setCompanyByName,
  formSectionSaved,
  saveFormSection,
  savePeopleExperienceSection,
  savePeoplePortfolioSection,
  saveCompanyPortfolioSection,
  saveCompanyInvestorsSection,
  saveCompanyInvestmentRoundsSection,
  saveInviteCompany,
  saveSettings,
  saveSharedCompaniesList,
  saveSharedCompaniesListDraft,
  sendSharedCompaniesList,
  previewSharedCompaniesList,
  sendSharedCompaniesFounderInvites,
  sendPasswordChange,
  saveCompanyCoachesSection,
  saveCompanyFoundersSection,
  saveDemoDayAttendanceSection,
  saveGeneralInfoSection,
  savePeopleAccountSection,
  saveMentorSection,
  saveFormSectionWithEmails,
  saveFormDesignatedFunds,
};
