/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const SET_SELECTED_PERSON = 'alchemist/People/Single/SET_SELECTED_PERSON';
export const SELECTED_PERSON_NOT_FOUND = 'alchemist/People/Single/SELECTED_PERSON_NOT_FOUND';
export const SELECTED_PERSON_CONNECTIONS_FETCH = 'alchemist/People/Single/SELECTED_PERSON_CONNECTIONS';
export const SELECTED_PERSON_CONNECTIONS_SUCCESS = 'alchemist/People/Single/SELECTED_PERSON_CONNECTIONS_SUCCESS';
export const SELECTED_PERSON_CONNECTIONS_FAIL = 'alchemist/People/Single/SELECTED_PERSON_CONNECTIONS_FAIL';
export const SET_SIMILAR_PROFILES = 'alchemist/People/Single/SET_SIMILAR_PROFILES';

export const SET_LP_FUNDS = 'alchemist/People/Single/SET_LP_FUNDS';
export const SET_FORM_LP_FUNDS_ERR = 'alchemist/People/Single/SET_FORM_LP_FUNDS_ERR';

