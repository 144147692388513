import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as NavLinkReact } from 'react-router-dom';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import ButtonBase from 'components/Buttons/ButtonBase';
import { makeSelectLocation } from 'containers/App/selectors';

import TooltipSimple from '../Tooltips/TooltipSimple';
import DropdownNavMenu from './DropdownNavMenu';


const MainContentHeader = ({ headerLinks, children, scrollable, loc, className, onClickToRun }) => {
  const location = loc || window.location;
  return (
    <div className={`dsa-main__header ${className} ${scrollable ? '_scrollable-tabs_' : ''}`}>
      <Nav tabs fill className={`dsa-nav-main ${scrollable ? '_scrollable_' : ''}`}>
        {headerLinks && headerLinks.filter(Boolean).map((link) => (
          <NavItem key={link.label} className={link.notification ? '_has-notification_' : ''}>
            {link.isDropdownNav
              ? (
                <ButtonBase className={classNames('nav-link__like me-0', { active: location.pathname.includes(link.to) })} to={link.to}>
                  {link.label}
                  <DropdownNavMenu>
                    {link.dropdownItems.map((item) => (
                      <NavLink key={item.to} tag={NavLinkReact} exact={item.exact} to={item.to}>
                        {item.label}
                      </NavLink>
                    ))}
                  </DropdownNavMenu>
                </ButtonBase>
              ) : (
                <NavLink
                  tag={NavLinkReact}
                  exact={link.exact}
                  to={link.to}
                  className={classNames({ 'dsa-admin-txt': link.isAdmin, disabled: link.disabled })}
                  active={location.pathname === link.to}
                  onClick={onClickToRun ? () => onClickToRun(link) : null}
                >
                  {link.label}
                  {link.notification && (
                    <TooltipSimple Component="span" tooltipText={link.notification}>
                      <FontAwesomeIcon icon={faCircleSolid} className="dsa-notification-circle" />
                    </TooltipSimple>)}
                  {link.count && ` (${link.count})`}
                </NavLink>
              )
            }
          </NavItem>
        ))}
      </Nav>
      {children}
    </div>
  );
};

MainContentHeader.propTypes = {
  children: PropTypes.any,
  headerLinks: PropTypes.array,
  scrollable: PropTypes.bool,
  loc: PropTypes.object,
  className: PropTypes.string,
  onClickToRun: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loc: makeSelectLocation(),
});

export default connect(mapStateToProps)(MainContentHeader);
