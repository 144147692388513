import { createSelector } from 'reselect';

import { makeSubSelector } from 'utils/selectors';
import { makeSelectLocation, makeSelectObjectFromRefSelector } from 'containers/App/selectors';

import reducer from './reducer';

const localInitialState = reducer(undefined, {});

const selectCompanySinglePage = (state) => state ? state.get('companySinglePage', localInitialState) : localInitialState;

const makeSelectCompanyTeamIsLoading = () => makeSubSelector(selectCompanySinglePage, ['companyTeamIsLoading']);
const makeSelectCompanyNotFound = () => makeSubSelector(selectCompanySinglePage, ['notFound']);
const makeSelectCompanyRef = () => makeSubSelector(selectCompanySinglePage, ['company']);
const makeSelectCompany = () => makeSelectObjectFromRefSelector(makeSelectCompanyRef());
const selectIsPublic = createSelector(
  makeSelectLocation(),
  (location) => location.pathname.match(/companies\/public\//)
);
const selectIsIfs = createSelector(
  makeSelectLocation(),
  (location) => location.pathname.match(/ifs/)
);

// returns ongoing or future demo days
const selectDdRelsStartupParticipatesIn = createSelector(
  makeSelectCompany(),
  (company) => {
    const companyOngoingOrFutureAclassDdRels = company?.aclass_demodays_rel?.().filter((rel) => {
      if (rel?.aclass?.().livestream_end_tzaware) {
        return new Date() < new Date(rel.aclass().livestream_end_tzaware);
      }

      if (rel?.aclass?.().demo_date_pst_aware) {
        return new Date() < new Date(rel.aclass().demo_date_pst_aware);
      }

      return false;
    });
    return companyOngoingOrFutureAclassDdRels;
  }
);

// returns attended/passed demo days
const selectDdRelsStartupParticipatedIn = createSelector(
  makeSelectCompany(),
  (company) => {
    const ddRelsStartupParticipatedIn = company?.aclass_demodays_rel?.().filter((rel) => {
      if (rel?.aclass?.().livestream_end_tzaware) {
        return new Date() > new Date(rel.aclass().livestream_end_tzaware);
      }

      if (rel?.aclass?.().demo_date_pst_aware) {
        return new Date() > new Date(rel.aclass().demo_date_pst_aware);
      }

      return false;
    });
    return ddRelsStartupParticipatedIn;
  }
);

export {
  makeSelectCompany,
  makeSelectCompanyRef,
  makeSelectCompanyTeamIsLoading,
  makeSelectCompanyNotFound,
  selectIsPublic,
  selectIsIfs,
  selectDdRelsStartupParticipatesIn, // ongoing
  selectDdRelsStartupParticipatedIn, // attended/passed
};

