import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import noop from 'lodash/noop';

import ScrollDiv from 'components/Generic/ScrollDiv';
import LoadingContent from 'components/LoadingSpinner/LoadingContent';
import LoadingSingle from 'components/LoadingSpinner/LoadingSingle';

import NoResultsMessage from './NoResultsMessage';
import { ResponsiveHoC } from '../Responsive/ResponsiveHoC';


function BasicListResults({
  onClickRow, selectedObject, className = '', ContentWrap = React.Fragment,
  noResultsMsg, noResultsContent, afterLastItemShow, isMobileTablet, onScroll, preventScrollBlock, isParentDiv, isHalfAdditional, ...props
}) {
  const ComponentToRender = props.component;
  const rowProps = props.rowProps || {};
  if (!props.items) {
    return (<Col className="dsa-list__results" />);
  }

  const noResultsComp = <NoResultsMessage text={noResultsMsg}> {noResultsContent} </NoResultsMessage>;

  const content = !props.items.length && !props.loading
    ? noResultsComp
    : props.items.map((item) => (
      <ComponentToRender
        key={`item-${item.id}-${item.pageNumber}`}
        item={item}
        isActive={selectedObject && selectedObject.id === item.id}
        isHalf={!!selectedObject || isHalfAdditional}
        onClickRow={onClickRow}
        {...rowProps}
      />
    ));

  if (!props.hasMore && props.items.length) {
    content.push(afterLastItemShow);
  }

  const WrapperElement = props.isMobileAndDemoDay || isParentDiv ? 'div' : ScrollDiv;

  return (
    <Col className={`${className} dsa-list__results-col`}>
      <div className="dsa-list__results-border-top" />
      {props.loading
        ? <LoadingContent text="Loading results..." textColor="sec" />
        : (
          <WrapperElement
            className="dsa-list__results"
            blockScroll={isMobileTablet && !!selectedObject && !preventScrollBlock}
            onScroll={onScroll}
          >
            <InfiniteScroll
              pageStart={0}
              hasMore={!props.loading && !props.loadingNextPage && props.hasMore}
              loadMore={props.loadNextPage || noop}
              loader={null}
              useWindow={false}
              className="dsa-infinite-scroll"
            >
              <ContentWrap>{content}</ContentWrap>
              {props.loadingNextPage && <LoadingSingle />}
            </InfiniteScroll>
          </WrapperElement>)}
    </Col>
  );
}

BasicListResults.propTypes = {
  component: PropTypes.any.isRequired,
  items: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  loadNextPage: PropTypes.func,
  onClickRow: PropTypes.func,
  selectedObject: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  hasMore: PropTypes.bool,
  loadingNextPage: PropTypes.bool,
  loading: PropTypes.bool,
  isMobileTablet: PropTypes.bool,
  rowProps: PropTypes.object,
  ContentWrap: PropTypes.any,
  className: PropTypes.string,
  noResultsMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noResultsContent: PropTypes.any,
  afterLastItemShow: PropTypes.any,
  isMobileAndDemoDay: PropTypes.bool,
  onScroll: PropTypes.func,
  preventScrollBlock: PropTypes.bool,
  isHalfAdditional: PropTypes.bool,
  isParentDiv: PropTypes.bool,
};

export default ResponsiveHoC(BasicListResults);
