import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';

import Tooltip from 'components/Generic/Tooltip';
import { AAHighlight } from 'components/Search/AAHighlight';
import { ParSmall } from 'components/Text/Body';
import { truncateInnerHTML } from 'utils/general';

import ListColBase from './ListColBase';


class ListColTwoLines extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = { showTooltip: true };
    this.handleTruncatedContent = this.handleTruncatedContent.bind(this);
    this.debouncedHandleTruncatedContent = debounce(this.handleTruncatedContent, 500);
  }

  componentDidMount() {
    this.handleTruncatedContent();
    window.addEventListener('resize', this.debouncedHandleTruncatedContent, false);
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.isHalfNotHidden && this.props.isHalfNotHidden)
      || (prevProps.isHalfNotHidden && !this.props.isHalfNotHidden)
      || (!prevProps.hideCol && this.props.hideCol)
      || (prevProps.width !== this.props.width)
    ) {
      this.handleTruncatedContent();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleTruncatedContent);
  }

  handleTruncatedContent() {
    if (this.props.hideCol) return;
    const colEl = this.ref?.current;
    const { needsTooltip, originalHTML } = truncateInnerHTML(colEl, this.state.originalHTML);
    if (colEl) {
      this.setState(() => ({ showTooltip: this.props.hideTooltip ? false : needsTooltip, originalHTML }));
    }
  }

  render() {
    const { children, bottomGradient, hideTooltip, ...props } = this.props;
    const { showTooltip } = this.state;
    return (
      <ListColBase twoLines {...omit(props, ['isHalfNotHidden'])}>
        {!hideTooltip
          ? (
            <div ref={this.ref}>
              <span>{children}</span>
            </div>)
          : (
            children
          )
        }
        {showTooltip && !hideTooltip && (
          <Tooltip
            placement="top"
            autohide={false}
            targetRef={this.ref}
            delay={150}
            className="dsa-tooltip-prim"
          >
            <ParSmall className="mb-0 dsa-text-sec text-left"><AAHighlight>{children}</AAHighlight></ParSmall>
          </Tooltip>)}
      </ListColBase>
    );
  }
}

ListColTwoLines.defaultProps = {
  hideTooltip: false,
};

ListColTwoLines.propTypes = {
  bottomGradient: PropTypes.bool,
  isHalfNotHidden: PropTypes.bool,
  hideCol: PropTypes.bool,
  children: PropTypes.any,
  hideTooltip: PropTypes.bool,
  width: PropTypes.string,
};

export default ListColTwoLines;
