import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { faSlack } from '@fortawesome/free-brands-svg-icons/faSlack';
import { faLifeRing } from '@fortawesome/pro-light-svg-icons/faLifeRing';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faPresentation } from '@fortawesome/pro-solid-svg-icons/faPresentation';
import { faUsersClass } from '@fortawesome/pro-solid-svg-icons/faUsersClass';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faChartBar } from '@fortawesome/pro-solid-svg-icons/faChartBar';
import { faUserAlt as faUserAltSolid } from '@fortawesome/pro-solid-svg-icons/faUserAlt';
import { faWrench } from '@fortawesome/pro-solid-svg-icons/faWrench';
import { faIndustry } from '@fortawesome/pro-solid-svg-icons/faIndustry';
// import { faUniversity } from '@fortawesome/pro-solid-svg-icons/faUniversity';
import { faPhoneLaptop } from '@fortawesome/pro-regular-svg-icons/faPhoneLaptop';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPresentationScreen } from '@fortawesome/pro-light-svg-icons/faPresentationScreen';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import uniqBy from 'lodash/uniqBy';
import { faSquarePollVertical } from '@fortawesome/pro-solid-svg-icons';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import { PERMISSION, RESOURCE_PEOPLE, NETWORK_ONB, CLASS_TYPE } from 'containers/App/constants';
import { selectUpcomingAxClassForAdmin, selectUpcomingAaClassForAdmin, selectClassCoachAaClass, makeSelectUserIs, selectClassCoachAxClass, selectShowOnboardingPage, makeSelectAccount, selectFounderAaClass, selectFounderAxClass, selectUserCapability, selectHasMultiFounderCaps, selectHasMultiActiveClasses, selectClassFromContext } from 'containers/AuthProvider/selectors';
import MobileDesktopSwitcher from 'containers/MobileDesktopSwitcher';
import { loadDesktopVersion } from 'containers/MobileDesktopSwitcher/actions';
// import { trackLMSPage } from 'containers/AuthProvider/actions';
// import { getSsoTokenFromCookie } from 'utils/auth';
import { isMobileTabletDevice } from 'utils/browserDetection';
import EditIconContainer from 'components/Icons/EditIconContainer';
import { toggleEditSidebarModal } from 'containers/EditSidebarItemModal/actions';
import { makeSelectSiteSettingsSidebarLinks } from 'containers/EditSidebarItemModal/selectors';
import { hasAccessOtherThanThese } from 'utils/fieldProcessors';
import { orderClasses } from 'utils/postProccessObject';

import SideBarSection from './SideBarSection';
import { SideBarLinkItem } from './SideBarItem';
import HelpMenu from '../MainContent/HelpMenu';
import LiVerticalDivider from './LiVerticalDivider';
import IfsAgendaSidebarLink from './IfsAgendaSidebarLink';


const StandardSidebar = ({
  myRoles, myProfile, isLocked, children, closeSidebar, isDesktop, isTablet, loadDesktopView, upcomingAxClassForAdmin, openEditSidebarModal,
  sidebarLinks, upcomingAaClassForAdmin, isClassCoach, classCoachAaClass, classCoachAxClass,
  showOnboarding, myAccount, founderAaClass, founderAxClass, capability, hasMultiActiveClasses, hasMultiFounderCaps,
  classFromContext, isLP,
}) => {
  const isAdmin = myRoles?.includes(PERMISSION.admin);
  const isFounder = myRoles?.includes(PERMISSION.founder);
  // const jwtLMS = getSsoTokenFromCookie();
  const isMobileTabletOS = isMobileTabletDevice();
  const history = useHistory();
  const allowedToSidebarLinks = [PERMISSION.founder, PERMISSION.class_coach, PERMISSION.admin];

  const userDdInvites = myProfile?.account?.().demoDayClassByType?.();
  const aaDdClass = getMostRecentClass([
    hasMultiFounderCaps && hasMultiActiveClasses && classFromContext?.class_type !== CLASS_TYPE.alchemist
      ? null
      : founderAaClass,
    upcomingAaClassForAdmin,
    classCoachAaClass,
    userDdInvites?.alchemist,
  ].filter((aaClass) => aaClass));
  const axDdClass = orderClasses(
    uniqBy(
      [
        hasMultiFounderCaps && hasMultiActiveClasses && classFromContext?.class_type !== CLASS_TYPE.alchemistx
          ? null
          : founderAxClass,
        upcomingAxClassForAdmin,
        classCoachAxClass,
        userDdInvites?.alchemistx,
      ].filter((aaClass) => aaClass),
      'id'
    )
  )?.[0];

  useEffect(() => {
    if (isTablet && localStorage.getItem('mobileDesktopSwitcher') === 'Switched') {
      loadDesktopView();
      localStorage.removeItem('mobileDesktopSwitcher');
    }
  }, []);

  return (
    <SideBarSection>
      <SideBarLinkItem
        to={`/onboarding/${capability?.id}`}
        faIcon={faLifeRing}
        show={!!showOnboarding}
        onClick={closeSidebar}
      >Onboarding
      </SideBarLinkItem>
      {/* <SideBarLinkItem
        to="/lms"
        onClick={() => {
          trackLMSPageView();
          closeSidebar();
        }}
        faIcon={faUniversity}
        show={!!jwtLMS}
      >
        Courses
      </SideBarLinkItem> */}
      <SideBarLinkItem
        to={`/${RESOURCE_PEOPLE}`}
        onClick={closeSidebar}
        faIcon={isLocked ? faLock : faUserAltSolid}
        show={myAccount?.variety !== PERMISSION.partner
          || hasAccessOtherThanThese(myRoles, [NETWORK_ONB, PERMISSION.partner])
        }
      >
        People
      </SideBarLinkItem>
      <SideBarLinkItem to="/companies" onClick={closeSidebar} faIcon={isLocked ? faLock : faIndustry}>
        Startups
      </SideBarLinkItem>
      <SideBarLinkItem
        to="/resources"
        onClick={closeSidebar}
        faIcon={isLocked ? faLock : faWrench}
        show={![PERMISSION.lp, PERMISSION.corporate].includes(myAccount?.variety)
          || hasAccessOtherThanThese(myRoles, [NETWORK_ONB, PERMISSION.lp, PERMISSION.corporate])
        }
      >
        Resources
      </SideBarLinkItem>

      {isLP && myProfile?.lp_fund_accesses &&
        <SideBarLinkItem
          to="/lp-portal"
          onClick={closeSidebar}
          faIcon={isLocked ? faLock : faSquarePollVertical}
        >
          LP Portal
        </SideBarLinkItem>
      }

      {aaDdClass?.number &&
        <SideBarLinkItem
          to={`/demo-day/aa/${aaDdClass.number}/companies`}
          onClick={closeSidebar}
          faIcon={isLocked && !userDdInvites?.alchemist ? faLock : faPresentation}
        >
          Demo Day
        </SideBarLinkItem>
      }

      {axDdClass?.number &&
        <SideBarLinkItem
          to={`/demo-day/ax/${axDdClass.number}/companies`}
          onClick={closeSidebar}
          faIcon={isLocked && !userDdInvites?.alchemistx ? faLock : faPresentationScreen}
        >
          AX Demo Day
        </SideBarLinkItem>
      }

      <IfsAgendaSidebarLink closeSidebar={closeSidebar} isLocked={isLocked} />

      {(upcomingAaClassForAdmin || founderAaClass || classCoachAaClass) &&
        <SideBarLinkItem
          to={`/demo-day/aa/${(upcomingAaClassForAdmin || founderAaClass || classCoachAaClass).number}/registrants`}
          onClick={closeSidebar}
          faIcon={isLocked ? faLock : faUsersClass}
        >
          Registrants
        </SideBarLinkItem>
      }

      <SideBarLinkItem to="/admin" onClick={closeSidebar} faIcon={isLocked ? faLock : faCog} show={isAdmin && isDesktop} hasTopDivider>
        Settings
      </SideBarLinkItem>
      <SideBarLinkItem to="/dashboard" onClick={closeSidebar} faIcon={isLocked ? faLock : faChartBar} show={isAdmin && isDesktop}>
        Dashboard
      </SideBarLinkItem>
      {sidebarLinks?.sidebar_founder_link_1_title &&
        <SideBarLinkItem
          href={sidebarLinks.sidebar_founder_link_1}
          onClick={closeSidebar}
          faIcon={faCalendarAlt}
          show={isAdmin || isFounder || isClassCoach || allowedToSidebarLinks.includes(myAccount?.variety)}
          hasTopDivider
          containerClassName="position-relative"
          editComponent={isAdmin && (
            <EditIconContainer
              className="dsa-edit-sidebar-item"
              onClick={() => openEditSidebarModal('sidebar_founder_link_1')}
            >
              <FontAwesomeIcon icon={faPencil} />
            </EditIconContainer>
          )}
        >
          {sidebarLinks.sidebar_founder_link_1_title}
        </SideBarLinkItem>
      }
      {sidebarLinks?.sidebar_founder_link_2_title &&
        <SideBarLinkItem
          href={sidebarLinks.sidebar_founder_link_2}
          faIcon={faSlack}
          show={isAdmin || isFounder || isClassCoach || allowedToSidebarLinks.includes(myAccount?.variety)}
          containerClassName="position-relative"
          editComponent={isAdmin && (
            <EditIconContainer
              className="dsa-edit-sidebar-item"
              onClick={() => openEditSidebarModal('sidebar_founder_link_2')}
            ><FontAwesomeIcon icon={faPencil} />
            </EditIconContainer>
          )}
        >
          {sidebarLinks.sidebar_founder_link_2_title}
        </SideBarLinkItem>
      }
      {!sidebarLinks?.sidebar_founder_link_1_title && <LiVerticalDivider />}
      <HelpMenu />
      <SideBarLinkItem
        href="#"
        onClick={() => {
          closeSidebar();
          if (isTablet) {
            localStorage.setItem('mobileDesktopSwitcher', 'Switched');
          }
          // To Do: Think of another way to reload only when there's an open form
          return history.go(0);
        }}
        show={isTablet || (isDesktop && isMobileTabletOS)}
        hasTopDivider
        faIcon={faPhoneLaptop}
      >
        <MobileDesktopSwitcher isTablet={isTablet} />
      </SideBarLinkItem>
      {children}
    </SideBarSection>
  );
};

StandardSidebar.propTypes = {
  myProfile: PropTypes.object,
  myRoles: PropTypes.array,
  isLocked: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isTablet: PropTypes.bool,
  children: PropTypes.any,
  closeSidebar: PropTypes.func,
  // trackLMSPageView: PropTypes.func,
  loadDesktopView: PropTypes.func,
  upcomingAxClassForAdmin: PropTypes.object,
  openEditSidebarModal: PropTypes.func,
  sidebarLinks: PropTypes.object,
  isClassCoach: PropTypes.bool,
  classCoachAaClass: PropTypes.object,
  classCoachAxClass: PropTypes.object,
  upcomingAaClassForAdmin: PropTypes.object,
  showOnboarding: PropTypes.bool,
  myAccount: PropTypes.object,
  founderAaClass: PropTypes.object,
  founderAxClass: PropTypes.object,
  capability: PropTypes.object,
  hasMultiFounderCaps: PropTypes.bool,
  hasMultiActiveClasses: PropTypes.bool,
  classFromContext: PropTypes.object,
  isLP: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  myAccount: makeSelectAccount(),
  upcomingAxClassForAdmin: selectUpcomingAxClassForAdmin,
  sidebarLinks: makeSelectSiteSettingsSidebarLinks(),
  classCoachAaClass: selectClassCoachAaClass,
  classCoachAxClass: selectClassCoachAxClass,
  isClassCoach: makeSelectUserIs(PERMISSION.class_coach),
  isLP: makeSelectUserIs(PERMISSION.lp),
  upcomingAaClassForAdmin: selectUpcomingAaClassForAdmin,
  showOnboarding: selectShowOnboardingPage,
  founderAaClass: selectFounderAaClass,
  founderAxClass: selectFounderAxClass,
  capability: selectUserCapability,
  hasMultiFounderCaps: selectHasMultiFounderCaps,
  hasMultiActiveClasses: selectHasMultiActiveClasses,
  classFromContext: selectClassFromContext,
});

const mapDispatchToProps = (dispatch) => ({
  // trackLMSPageView: () => dispatch(trackLMSPage()),
  loadDesktopView: () => dispatch(loadDesktopVersion()),
  openEditSidebarModal: (sidebarItem) => dispatch(toggleEditSidebarModal(true, sidebarItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHoC(StandardSidebar));

const getMostRecentClass = (classes) => {
  const uniqueClasses = uniqBy(classes?.filter((aaClass) => aaClass), 'id');
  const futureDDs = uniqueClasses?.filter((aaClass) => new Date() < new Date(aaClass?.demo_date_pst_aware));
  const activeDDs = uniqueClasses?.filter((aaClass) => new Date() > new Date(aaClass?.demo_date_pst_aware) && new Date() < new Date(aaClass?.demo_access_end_date_pst_aware || aaClass?.demoday_active_until));

  // Returns Demo Day with passed demo_date but still active or still ongoing
  if (activeDDs.length > 0) {
    return minBy(activeDDs, 'demo_date_pst_aware');
  }

  // Returns earliest future Demo Day
  if (futureDDs.length > 0) {
    return minBy(futureDDs, 'demo_date_pst_aware');
  }

  // Returns last Demo Day with passed demo date
  return maxBy(uniqueClasses, 'demo_date_pst_aware');
};
