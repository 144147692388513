import { delay, call, put, select, takeLatest, debounce } from 'redux-saga/effects';
import get from 'lodash/get';

import { updateIncluded } from 'actions';
import { logError } from 'utils/log';
import { SET_QS_FOR_EXPERIENCES_SEARCH } from 'containers/People/Search/constants';

import { formatStr, updateLocation } from '../general';
import { extractData } from '../jsonApiExtract';
import { request } from '../request';
import { parseUrlFilters, putAction } from '../sagas';
import { generateFiltersActions, generateLoadedContentActions } from './actions';
import {
  INITIAL_LOAD,
  LOAD_CONTENT,
  LOAD_NEXT_PAGE,
  TOGGLE_SORT,
  SET_SORT,
  SET_CATEGORY,
  APPLY_TEMP_FILTERS,
  SET_ADV_FILTER,
  REMOVE_ADV_FILTER,
  APPLY_TEMP_FILTERS_KEY,
  CLEAR_FILTERS,
  SET_ADV_FILTERS_OPERATOR, APPLY_TEMP_FILTERS_GROUP, CLEAR_FILTERS_GROUP, SET_SEARCH, SET_SEARCH_OPERATOR,
  SET_SEARCH_WITHOUT_FILTERS,
} from './constants';
import { generateFiltersLocalString, generateFiltersString } from './filter';
import { generateFiltersSelectors, generateLoadedContentSelectors } from './selectors';

/**
 * Companies request/response handler
 */
export function* fetchObjects({
  isFetchNextPage, preprocessAppliedFilters, preprocessSort, baseClientUrl, baseApiUrl, pageIdentifier, pageSelector,
  filtersFirstChar = '&', fopOverride, controlClientUrl, nextPageFn = (p) => p + 1, filtersCustomSelector,
  loadedContentCustomSelector, loadedContentIdentifier, abortSignal, callback, contentMaxPageIsNeeded = false, _count, pageSize,
  preprocessAppliedFiltersOnlySearch,
}) {
  const {
    setHasMore, loadNextPageSuccess, contentLoaded, setTotalCount, contentLoadingError, setContentMaxPage,
  } = generateLoadedContentActions(loadedContentIdentifier || pageIdentifier);
  const { setMaxPage } = generateFiltersActions(pageIdentifier || pageIdentifier);
  const { makeSelectFilter, makeSelectMaxPage } = generateFiltersSelectors(
    pageSelector || pageIdentifier,
    filtersCustomSelector
  );
  const { makeSelectLoading, makeSelectLoadingNextPage, makeSelectMaxPageContent } = generateLoadedContentSelectors(
    pageSelector || pageIdentifier,
    loadedContentCustomSelector
  );
  const { makeSelectTotalCount, makeSelectTriedSearchWithoutFilters } = generateLoadedContentSelectors(
    pageSelector || pageIdentifier,
    loadedContentCustomSelector
  );

  // If a new selection is being loaded and next page is requested, wait for the new selection to finish loading
  // and vice-versa
  let isLoading = isFetchNextPage ? yield select(makeSelectLoading()) : yield select(makeSelectLoadingNextPage());
  while (isLoading) {
    yield delay(100);
    isLoading = isFetchNextPage ? yield select(makeSelectLoading()) : yield select(makeSelectLoadingNextPage());
  }

  // Select filters from store
  const filterImmutable = yield select(makeSelectFilter());

  const nextPage = isFetchNextPage ? nextPageFn(yield select(contentMaxPageIsNeeded ? makeSelectMaxPageContent() : makeSelectMaxPage())) : 0;

  const triedSearchWithoutFilters = yield select(makeSelectTriedSearchWithoutFilters());

  const filterString = yield getFiltersString({
    isFetchNextPage,
    preprocessAppliedFilters: triedSearchWithoutFilters ? preprocessAppliedFiltersOnlySearch : preprocessAppliedFilters,
    preprocessSort,
    pageSelector,
    filtersFirstChar,
    fopOverride: triedSearchWithoutFilters ? {} : fopOverride,
    pageIdentifier,
    contentMaxPageIsNeeded,
    loadedContentCustomSelector,
    _count,
    pageSize,
  });

  const requestURL = `${baseApiUrl}${filterString}`;
  try {
    // Call our request helper (see 'utils/request')
    const resourcesRequest = yield call(request, requestURL, { signal: abortSignal });
    const { inclusions, items: objectRefs } = extractData(resourcesRequest);
    const objects = objectRefs.map((obj) => ({ ...obj, pageNumber: nextPage }));

    let countRequest = null;
    let availableResults = yield select(makeSelectTotalCount());
    if (!isFetchNextPage && _count === 'False') {
      countRequest = yield call(request, `${requestURL}&_only_count=True`);
      availableResults = get(countRequest, 'meta.results.available');
      yield put(setTotalCount(availableResults || 0));
    }

    const hasMore = _count === 'False' && pageSize ? availableResults > ((nextPage || 1) * pageSize) : !!resourcesRequest.links.next;
    yield put(setHasMore(hasMore));
    yield put(setMaxPage(nextPage));

    if (makeSelectMaxPageContent) {
      yield put(setContentMaxPage(nextPage));
    }

    yield put(updateIncluded(inclusions));
    if (!preprocessAppliedFiltersOnlySearch) {
      yield isFetchNextPage ? put(loadNextPageSuccess(objects)) : put(contentLoaded(objects));
    }

    if (preprocessAppliedFiltersOnlySearch) {
      const appliedFilters = filterImmutable.get('appliedFilters');
      const searchFilter = appliedFilters.get('search');
      if (isFetchNextPage) yield put(loadNextPageSuccess(objects));

      if (
        !isFetchNextPage
        && (
          (!triedSearchWithoutFilters && objects.length) // found default results with filters
          || (!triedSearchWithoutFilters && !objects.length && !searchFilter) // no results with filters, but search term is missing, so we don't need to try request without filters
          || triedSearchWithoutFilters)
      ) {
        yield put(contentLoaded(objects));
      }
    }


    if (!isFetchNextPage && !_count) {
      yield put(setTotalCount(get(resourcesRequest, 'meta.results.available') || 0));
    }
    if (!controlClientUrl || controlClientUrl === window.location.pathname) {
      updateLocation(`${baseClientUrl || window.location.pathname}${generateFiltersLocalString(filterImmutable)}`);
    }

    if (callback) {
      yield* callback(resourcesRequest);
    }

    return resourcesRequest;
  } catch (err) {
    logError(err);
    yield put(contentLoadingError(err));
    return err;
  }
}

export function* getFiltersString({
  isFetchNextPage, preprocessAppliedFilters, preprocessSort, pageSelector, filtersFirstChar = '&', fopOverride,
  pageIdentifier, contentMaxPageIsNeeded, loadedContentCustomSelector, _count, pageSize,
}) {
  const { makeSelectFilter, makeSelectMaxPage, selectTmpAdvFiltersOperator } = generateFiltersSelectors(pageSelector || pageIdentifier);

  const { makeSelectMaxPageContent } = generateLoadedContentSelectors(
    pageSelector || pageIdentifier,
    loadedContentCustomSelector
  );

  const filterImmutable = yield select(makeSelectFilter());
  const advFiltersOperator = yield select(selectTmpAdvFiltersOperator);
  const nextPage = isFetchNextPage ? (yield select(contentMaxPageIsNeeded ? makeSelectMaxPageContent() : makeSelectMaxPage())) + 1 : 0;
  const processedFilterImmutable = preprocessAppliedFilters
    && (yield preprocessAppliedFilters(filterImmutable.get('appliedFilters')));
  return generateFiltersString(
    processedFilterImmutable,
    preprocessSort && preprocessSort(filterImmutable.get('sort')),
    nextPage,
    filtersFirstChar,
    advFiltersOperator,
    fopOverride,
    _count,
    pageSize,
  );
}

export const getStandardSagas = ({
  pageIdentifier, baseApiUrl, controlClientUrl, preprocessAppliedFilters, preprocessSort, overrideFetchOptionsSaga, callback, preprocessAppliedFiltersOnlySearch,
}) => {
  const { makeSelectAdvancedFilters } = generateFiltersSelectors(pageIdentifier);
  const { setFiltersAndSort } = generateFiltersActions(pageIdentifier);
  const { setSelectedObject, loadContent } = generateLoadedContentActions(pageIdentifier);

  const filtersFirstChar = baseApiUrl && (baseApiUrl.includes('?') ? '&' : '?');

  function* getObjects(isFetchNextPage) {
    let fetchOptionsOverride;
    if (overrideFetchOptionsSaga) {
      fetchOptionsOverride = yield overrideFetchOptionsSaga();
      if (fetchOptionsOverride === false) return;
    }
    const res = yield fetchObjects({
      isFetchNextPage,
      preprocessAppliedFilters,
      preprocessAppliedFiltersOnlySearch,
      preprocessSort,
      baseApiUrl,
      controlClientUrl,
      pageIdentifier,
      filtersFirstChar,
      callback,
      ...fetchOptionsOverride,
    });

    // eslint-disable-next-line consistent-return
    return res;
  }

  function* loadContentOnOperatorChange() {
    const advFilters = yield select(makeSelectAdvancedFilters());
    if (advFilters?.length > 1) {
      yield put(loadContent());
    }
  }

  /**
   * Load the filters from the url search parameters
   */
  function* initialLoadSaga() {
    const queryParams = yield parseUrlFilters();
    yield put(setFiltersAndSort(queryParams.filter || {}, queryParams.sort));
    yield put(setSelectedObject(false));
    // yield put(loadContent());
    if (preprocessAppliedFiltersOnlySearch) {
      yield trySearchAndProceedWithoutFiltersSaga();
    } else {
      yield put(loadContent());
    }
  }

  /**
 * saga to search without applying filters.
 *
 * 1. attempts to retrieve objects (e.g., companies)
 * without any filters applied in case if there are no results with filters.
 * 2. if no data is retrieved, sets a flag
 * 3. indicating the attempt and triggers content loading.
 *
 *  @generator
  * @yields {void} - yields effects to Redux-Saga middleware.
 */
  function* trySearchAndProceedWithoutFiltersSaga() {
    const { setTriedSearchWithoutFilters, setContentLoading } = generateFiltersActions(pageIdentifier || pageIdentifier);
    const { makeSelectTriedSearchWithoutFilters } = generateLoadedContentSelectors(pageIdentifier || pageIdentifier);
    const triedSearchWithoutFilters = yield select(makeSelectTriedSearchWithoutFilters());
    if (triedSearchWithoutFilters) yield put(setTriedSearchWithoutFilters(false));

    yield put(setContentLoading(true));
    const companiesReq = yield call(getObjects, false);
    const { data } = companiesReq;

    if (Array.isArray(data) && data.length === 0) {
      yield put(setTriedSearchWithoutFilters(true));
      yield delay(100);
      yield put(loadContent());
    }
  }

  /**
    * saga for loading content with filters applied.
    * this generator function handles the following steps:
    * 1. retrieves the filter state selector for managing applied filters.
    * 2. checks if a search filter is applied.
    *    - ff present, attempts a search and proceeds without filters if necessary (if no resutls found. see trySearchAndProceedWithoutFiltersSaga).
    *    - otherwise, triggers content loading with a short delay.
    *
    * This function ensures the content is loaded based on the current applied filters
    * and manages scenarios where filters need to be ignored for a search attempt.
    *
    * @generator
    * @yields {void} - Yields effects to Redux-Saga middleware.
 */
  function* loadContentWithFiltersSaga() {
    const { makeSelectFilter } = generateFiltersSelectors(pageIdentifier);

    const filterImmutable = yield select(makeSelectFilter());
    const appliedFilters = filterImmutable.get('appliedFilters');
    const searchFilter = appliedFilters.get('search');

    if (searchFilter) {
      yield trySearchAndProceedWithoutFiltersSaga();
    } else {
      yield delay(100);
      yield put(loadContent());
    }
  }

  function* testExpSearchSaga() {
    const { makeSelectFilter } = generateFiltersSelectors(pageIdentifier);

    const filterImmutable = yield select(makeSelectFilter());
    const appliedFilters = filterImmutable.get('appliedFilters');
    const searchFilter = appliedFilters.get('search');
    if (searchFilter) yield put(loadContent());
  }

  return function* mainSaga() {
    yield takeLatest(formatStr(INITIAL_LOAD, pageIdentifier), initialLoadSaga);
    yield takeLatest(formatStr(LOAD_CONTENT, pageIdentifier), getObjects, false);
    yield takeLatest(formatStr(LOAD_NEXT_PAGE, pageIdentifier), getObjects, true);
    yield takeLatest(SET_QS_FOR_EXPERIENCES_SEARCH, testExpSearchSaga);
    /**
     * Only trigger the search when the user stops to breath
     */
    yield debounce(1000, formatStr(SET_SEARCH, pageIdentifier), putAction, loadContent());
    yield debounce(1000, formatStr(SET_SEARCH_WITHOUT_FILTERS, pageIdentifier), trySearchAndProceedWithoutFiltersSaga);

    yield takeLatest(
      [
        TOGGLE_SORT, SET_SORT, SET_CATEGORY, CLEAR_FILTERS, APPLY_TEMP_FILTERS, SET_ADV_FILTER, REMOVE_ADV_FILTER,
        APPLY_TEMP_FILTERS_KEY, APPLY_TEMP_FILTERS, APPLY_TEMP_FILTERS_GROUP, CLEAR_FILTERS_GROUP, SET_SEARCH_OPERATOR,
      ].map((actionType) => formatStr(actionType, pageIdentifier)),
      loadContentWithFiltersSaga
    );
    yield takeLatest(formatStr(SET_ADV_FILTERS_OPERATOR, pageIdentifier), loadContentOnOperatorChange);
  };
};

// export function* checkSelectedObject() {
//   const selectedObject = yield select(makeSelectSelectedObject());
//   if (!selectedObject) return;
//
//   const objects = yield select(makeSelectPeople());
//   yield put(setSelectedObject(objects.find((obj) => selectedObject.toJS().id === obj.id) || false));
// }
