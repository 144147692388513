import { createSelector } from 'reselect';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import filter from 'lodash/filter';

import { makeSubSelector, makeSubSelectorToJS } from 'utils/selectors';
import { CAPABILITIES, PERMISSION, RELATIONS, CLASS_TYPE } from 'containers/App/constants';
import { FOUNDER_COMPANY } from 'containers/FounderChooseModal/constants';
import { makeSelectLocation, makeSelectObjectArrayFromRefArray, makeSelectObjectFromRefSelector, selectIncluded } from 'containers/App/selectors';
import { resRef } from 'utils/refs';
import { AJ_CLASS_PARENT_COMPANY_NAME } from 'containers/Resources/Program/constants';


const selectAuth = (state) => state.get('auth');

const makeSelectUser = () => makeSubSelectorToJS(selectAuth, ['user']);
const selectUser = makeSubSelectorToJS(selectAuth, ['user']);

const selectToken = makeSubSelectorToJS(selectAuth, ['token']);

const makeSelectSiteSettings = () => createSelector(
  selectAuth,
  (siteSettingsState) => siteSettingsState ? siteSettingsState.get('siteSettings').toJS() || {} : {}
);
const selectSignaturesRefs = makeSubSelector(selectAuth, ['signatures']);
const makeSelectSignatures = () => makeSelectObjectArrayFromRefArray(selectSignaturesRefs);

const makeSelectIsAuthenticated = () => makeSubSelector(selectAuth, ['authenticated']);
const selectIsLoggingOut = makeSubSelector(selectAuth, ['loggingOut']);

const makeSelectAuthPrincipals = () => createSelector(selectUser, (user) => user?.roles); // refers to TOKEN roles
const selectHasAccount = createSelector(selectUser, (user) => user && !user.no_account);
const makeSelectIsFormLogin = () => createSelector(selectUser, (user) => user?.from_login_form);
const selectAccountLoaded = makeSubSelector(selectAuth, ['accountLoaded']);
const selectCompaniesDataLoaded = makeSubSelector(selectAuth, ['companiesDataLoaded']);

const makeSelectUserIs = (role) => createSelector(
  makeSelectAuthPrincipals(),
  (principals) => principals && principals.includes(role),
);
const makeSelectUserIsOneOf = (roles) => createSelector(
  makeSelectAuthPrincipals(),
  (userPrincipals) => userPrincipals && roles.some((role) => userPrincipals.includes(role)),
);

const makeSelectUserHasOnboarding = () => createSelector(
  makeSelectAuthPrincipals(),
  makeSelectUserIs('onboarding'),
  (principals, isOnboarding) => isOnboarding || (principals && principals.some((principal) => CAPABILITIES.hasOnboarding(principal))),
);

const makeSelectUserCanComment = () => createSelector(
  makeSelectAuthPrincipals(),
  (principals) => principals && principals.some((principal) => CAPABILITIES.canUseComments(principal)),
);

const makeSelectUserCanUseLists = () => createSelector(
  makeSelectAuthPrincipals(),
  (principals) => principals && principals.some((principal) => CAPABILITIES.canUseLists(principal)),
);

const makeSelectUserCanUseResources = () => createSelector(
  makeSelectAuthPrincipals(),
  (principals) => principals && principals.some((principal) => CAPABILITIES.canUseResources(principal)),
);

const makeSelectUserCanAccessPeoplePage = () => createSelector(
  makeSelectAuthPrincipals(),
  (principals) => principals && principals.some((principal) => CAPABILITIES.canAccessPeoplePage(principal)),
);

const makeSelectHighestUserAccess = () => createSelector(
  makeSelectAuthPrincipals(),
  (userTokenPrincipals) => {
    const uniqueRoles = Array.from(new Set(userTokenPrincipals));
    const filteredRoles = uniqueRoles?.filter((r) => Object.keys(CAPABILITIES).includes(r));
    const sortedRoles = filteredRoles && sortBy(filteredRoles, (p) => CAPABILITIES[p]);
    return sortedRoles?.[0];
  }
);

const makeSelectIsCheckingAuth = () => makeSubSelector(selectAuth, ['checking']);

const selectProfileRef = makeSubSelector(selectAuth, ['profile']);
const makeSelectProfile = () => makeSelectObjectFromRefSelector(makeSubSelector(selectAuth, ['profile']));
const makeSelectMyCompany = () => createSelector(
  makeSelectProfile(),
  (myProfile) => myProfile && myProfile.company && myProfile.company(),
);

const makeSelectMyCompanyTheme = () => createSelector(
  makeSelectMyCompany(),
  (myCompany) => ({
    themePrimaryColor: myCompany?.theme_primary_color,
    themeLinkColor: myCompany?.theme_link_color,
    themeType: myCompany?.theme_type,
  }),
);

const selectUpcomingClass = makeSelectObjectFromRefSelector(makeSubSelector(selectAuth, ['upcomingClass']));
const selectUpcomingClassX = makeSelectObjectFromRefSelector(makeSubSelector(selectAuth, ['upcomingClassX']));
const selectClassFromLinkedinSignIn = makeSelectObjectFromRefSelector(makeSubSelector(selectAuth, ['classFromLinkedinSignin']));
const selectActiveAxClassesRefs = makeSubSelector(selectAuth, ['activeAxClasses']);
const makeSelectActiveAxClasses = () => makeSelectObjectArrayFromRefArray(selectActiveAxClassesRefs);

const selectFounderCompanyClass = createSelector(
  makeSelectMyCompany(),
  makeSelectUserIsOneOf([PERMISSION.admin, PERMISSION.founder]),
  (company, isAdminOrFounder) => isAdminOrFounder && company && company.aclass && company.aclass()
);

const selectUpcomingAaClassForAdmin = createSelector(
  makeSelectUserIs(PERMISSION.admin),
  selectUpcomingClass,
  (isAdmin, upcomingClass) => {
    if (isAdmin) {
      return upcomingClass;
    }
    return null;
  }
);

// class_website_available_for_founders is True beginning 4 weeks before the demodate of the current class and until 4 weeks before the demo date of the next class. If next class does not exist, then class_website_available_for_founders is True until 13 weeks after the demodate of the current class.
const selectFounderAaClass = createSelector(
  makeSelectUserIs(PERMISSION.founder),
  makeSelectMyCompany(),
  (isFounder, founderCompany) => {
    if (!isFounder) {
      return false;
    }
    // `aclass_demodays_rel` returns DDs that the startup is part of and will participate in
    // AND DDs that the startup will participate in as ALUMNI
    const classes = founderCompany?.aclass_demodays_rel?.().map((rel) => rel.aclass?.());
    const activeClasses = classes?.filter((aclass) => aclass?.class_type === CLASS_TYPE.alchemist && aclass?.class_website_available_for_founders);
    const currentClass = minBy(activeClasses, (aclass) => aclass?.demo_date_pst_aware);
    return isFounder && currentClass;
  }
);

const selectFounderAxClass = createSelector(
  makeSelectUserIs(PERMISSION.founder),
  makeSelectMyCompany(),
  (isFounder, founderCompany) => {
    if (!isFounder) {
      return false;
    }
    const classes = founderCompany?.aclass_demodays_rel?.().map((rel) => rel.aclass?.());
    const activeClasses = classes?.filter((aclass) => aclass.class_type === CLASS_TYPE.alchemistx && aclass.class_website_available_for_founders);
    const currentClass = minBy(activeClasses, (aclass) => aclass.demo_date_pst_aware);
    return isFounder && currentClass;
  }
);

const makeSelectFounderClassUsing = (classId) => createSelector(
  makeSelectUserIs(PERMISSION.founder),
  makeSelectMyCompany(),
  (isFounder, founderCompany) => {
    const classes = founderCompany?.aclass_demodays_rel?.().map((rel) => rel.aclass());
    const founderClass = find(classes, (aclass) => aclass.id === parseInt(classId, 10));
    return isFounder && founderClass;
  }
);

const selectClassFromContext = createSelector(
  makeSelectMyCompany(),
  (founderCompany) => {
    const startupClassContext = JSON.parse(localStorage.getItem(FOUNDER_COMPANY));
    return find(founderCompany?.aclasses_rel?.(), (aRel) => aRel?.aclass?.().id === startupClassContext?.aclass)?.aclass?.();
  }
);


const selectClassCoachAaClass = createSelector(
  makeSelectUserIs(PERMISSION.class_coach),
  makeSelectProfile(),
  (isClassCoach, myProfile) => {
    if (isClassCoach) {
      const activeAaClasses = myProfile?.coach_classes?.().filter((aclass) => !aclass.isAlchemistX && aclass.class_website_available_for_founders);
      return minBy(activeAaClasses, (aclass) => aclass.demo_date_pst_aware);
    }
    return null;
  }
);

const selectClassCoachAxClass = createSelector(
  makeSelectUserIs(PERMISSION.class_coach),
  makeSelectProfile(),
  (isClassCoach, myProfile) => {
    if (isClassCoach) {
      const activeAaClasses = myProfile?.coach_classes?.().filter((aclass) => aclass.isAlchemistX && aclass.class_website_available_for_founders);
      return minBy(activeAaClasses, (aclass) => aclass.demo_date_pst_aware);
    }
    return null;
  }
);

const selectUpcomingAxClassForAdmin = createSelector(
  selectUpcomingClassX,
  makeSelectUserIs(PERMISSION.admin),
  (upcomingAxClass, isAdmin) => isAdmin && upcomingAxClass
);

const makeSelectAccount = () => createSelector(
  makeSelectProfile(),
  (profile) => profile && profile.account && profile.account(),
);

const makeSelectNotifications = () => createSelector(
  makeSelectAccount(),
  (myAccount) => myAccount && myAccount.notifications && myAccount.notifications(),
);

const selectCapabilityFromToken = createSelector(
  makeSelectAccount(),
  makeSelectAuthPrincipals(),
  (myAccount, authPrincipals) => {
    const isOnboarding = authPrincipals?.includes('onboarding');
    const isNetworkOnboarding = authPrincipals?.includes('network_onboarding');
    let capabilityId = null;
    if (isOnboarding) {
      const onboardingRole = find(authPrincipals, (r) => r.includes('onboarding:'));
      capabilityId = onboardingRole?.split(':')?.[1];
    } else if (isNetworkOnboarding) {
      const networkOnboardingRole = find(authPrincipals, (r) => r.includes('network_onboarding:'));
      capabilityId = networkOnboardingRole?.split(':')?.[1];
    }
    return capabilityId && find(myAccount?.capabilities_rel?.(), (rel) => rel.id === Number(capabilityId));
  }
);

const selectCapabilityFromUrl = createSelector(
  makeSelectLocation(),
  makeSelectAccount(),
  (location, myAccount) => {
    const capabilityIdFromUrl = location.pathname.startsWith('/onboarding/') ? location.pathname.split('/')?.[2] : null;
    return capabilityIdFromUrl && find(myAccount?.capabilities_rel?.(), (rel) => rel?.id === Number(capabilityIdFromUrl));
  }
);

const selectUserCapability = createSelector(
  makeSelectAccount(),
  makeSelectMyCompany(),
  selectCapabilityFromToken,
  selectCapabilityFromUrl,
  (myAccount, myCompany, capabilityIdFromToken, capabilityFromUrl) => capabilityFromUrl || capabilityIdFromToken || find(myAccount?.capabilities_rel?.(), (rel) => rel.aclass_company?.().aclass?.().id === (myCompany?.aclass?.().id || myCompany?.aclass_id))
);

const selectHasMultiFounderCaps = createSelector(
  makeSelectAccount(),
  (myAccount) => myAccount?.capabilities_rel?.().filter((c) => c.capability === CAPABILITIES.founder && c.active)?.length > 1
);

const selectHasMultiActiveClasses = createSelector(
  makeSelectMyCompany(),
  (myCompany) => {
    const activeClasses = filter(myCompany?.aclasses_rel?.(), (aRel) => aRel?.aclass?.().considered_active
      || new Date() < new Date(aRel?.aclass?.().demo_date_pst_aware)
      || aRel?.class_type === CLASS_TYPE.alchemist);
    return activeClasses?.length > 1;
  }
);

const selectCanChangePrimaryCompany = createSelector(
  makeSelectUserIs('onboarding'),
  makeSelectUserIs('founder'),
  makeSelectMyCompany(),
  (isOnboarding, isFounder, myCompany) => isOnboarding
    || (isFounder && myCompany && myCompany.aclass && myCompany.aclass().active)
);

const selectFounderIsAA = createSelector(
  makeSelectUserIs('founder'),
  makeSelectMyCompany(),
  (isFounder, myCompany) => !!(isFounder && myCompany?.aclass?.()?.class_type === CLASS_TYPE.alchemist)
);

const selectFounderIsAX = createSelector(
  makeSelectUserIs('founder'),
  makeSelectMyCompany(),
  (isFounder, myCompany) => !!(isFounder && myCompany?.aclass?.()?.class_type === CLASS_TYPE.alchemistx)
);

const selectFounderIsAJ = createSelector(
  makeSelectUserIs('founder'),
  makeSelectMyCompany(),
  (isFounder, myCompany) => !!(isFounder && myCompany?.aclass?.()?.parent_company?.()?.name?.toLowerCase() === AJ_CLASS_PARENT_COMPANY_NAME)
);

const makeSelectListsForModalAreLoading = () => makeSubSelector(selectAuth, ['listsForModalLoading']);

const selectHasOnboardedOverTwoYearsAgo = createSelector(
  makeSelectUserIs(PERMISSION.admin),
  selectUserCapability,
  (isAdmin, founderCapability) => !isAdmin && founderCapability
    && founderCapability.capability === CAPABILITIES.founder
    && founderCapability.active
    && founderCapability.onboarding_last_end_date
    && new Date(founderCapability.onboarding_last_end_date) < new Date(new Date().getFullYear() - 2, new Date().getMonth(), new Date().getDate())
);

const selectShowOnboardingPage = createSelector(
  makeSelectAuthPrincipals(),
  makeSelectProfile(),
  selectFounderCompanyClass,
  selectHasOnboardedOverTwoYearsAgo,
  (principals, myProfile, founderCompanyClass, hasOnboardedOverTwoYearsAgo) => {
    const showOnboardingPage = principals?.includes('onboarding')
    || (
      founderCompanyClass?.active &&
      [RELATIONS.FOUNDER, RELATIONS.EMPLOYEE].includes(myProfile?.primaryMembership?.().relation)
    )
    || hasOnboardedOverTwoYearsAgo;
    return showOnboardingPage;
  }
);

const selectLatestIfsInvitation = createSelector(
  makeSelectProfile(),
  (myProfile) => {
    const ifsInvitations = myProfile?.ifs_invitations?.() || [];
    return maxBy(ifsInvitations, 'invited_at');
  }
);

const selectAlchemistClassesFromIncluded = makeSubSelectorToJS(selectIncluded, ['alchemist_classes']);
const selectAlchemistClassRefs = createSelector(
  selectAlchemistClassesFromIncluded,
  (aclassesFromIncluded) => aclassesFromIncluded && Object.values(aclassesFromIncluded).map((a) => resRef(a))
);
const selectAlchemistClasses = makeSelectObjectArrayFromRefArray(selectAlchemistClassRefs);
const makeSelectAlchemistClassUsing = (aaClassNumber) => createSelector(
  selectAlchemistClasses,
  makeSelectLocation(),
  (aaClasses, location) => {
    const classType = location.pathname.includes('/ax') ? CLASS_TYPE.alchemistx : CLASS_TYPE.alchemist;
    return find(aaClasses, (aaClass) => aaClass?.number === Number(aaClassNumber) && aaClass?.class_type === classType);
  }
);

export {
  selectAuth,
  selectToken,
  makeSelectProfile,
  makeSelectMyCompany,
  makeSelectAccount,
  makeSelectUser,
  selectUser,
  makeSelectIsAuthenticated,
  makeSelectIsCheckingAuth,
  makeSelectSiteSettings,
  makeSelectSignatures,
  selectCanChangePrimaryCompany,
  makeSelectNotifications,
  makeSelectUserIs,
  makeSelectUserIsOneOf,
  makeSelectAuthPrincipals,
  makeSelectIsFormLogin,
  selectAccountLoaded,
  selectCompaniesDataLoaded,
  selectIsLoggingOut,
  selectUpcomingClass,
  selectUpcomingClassX,
  selectClassFromLinkedinSignIn,
  makeSelectActiveAxClasses,
  selectFounderCompanyClass,
  selectHasAccount,
  selectProfileRef,
  selectClassCoachAaClass,
  selectClassCoachAxClass,
  selectUpcomingAxClassForAdmin,
  selectUpcomingAaClassForAdmin,
  makeSelectMyCompanyTheme,
  makeSelectListsForModalAreLoading,
  makeSelectHighestUserAccess,
  makeSelectUserHasOnboarding,
  makeSelectUserCanComment,
  makeSelectUserCanUseLists,
  selectFounderIsAA,
  selectFounderIsAX,
  selectShowOnboardingPage,
  makeSelectUserCanUseResources,
  selectLatestIfsInvitation,
  selectAlchemistClasses,
  makeSelectAlchemistClassUsing,
  selectFounderAaClass,
  selectFounderAxClass,
  makeSelectFounderClassUsing,
  selectUserCapability,
  selectHasMultiFounderCaps,
  selectHasOnboardedOverTwoYearsAgo,
  selectHasMultiActiveClasses,
  makeSelectUserCanAccessPeoplePage,
  selectClassFromContext,
  selectFounderIsAJ,
  selectCapabilityFromUrl,
};
