import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TooltipPrim from 'components/Tooltips/TooltipPrim';
import LinkToSinglePage from 'containers/BasicConnectedElements/LinkToSinglePage';
import { Stronger } from 'components/Text/Body';

import { Avatar } from './Avatar';


const NameAvatar = ({
  profile, target = '', className = '', avatarClassName, highlightText, children, noLink, prefix, icon, linkClassName,
  size, isStartupMultiClasses, isCompanyRow, customText, passedTo,
}) => (
  <div className={`dsa-avatar__name ${className}`}>
    <Avatar profile={profile} className={avatarClassName || 'mx-6'} size={size || ''} isStartupMultiClasses={isStartupMultiClasses} isCompanyRow={isCompanyRow} />
    <TooltipPrim text={profile.name} highlightText={highlightText} tooltipClassName="list-col" className="flex-grow-1">
      {!!icon && <FontAwesomeIcon icon={icon} className="mr-3" />}
      {prefix && <Stronger>{prefix}</Stronger>}
      <LinkToSinglePage highlightText={highlightText} target={target} obj={profile} noLink={noLink} linkClassName={linkClassName} customText={customText} passedTo={passedTo} />
    </TooltipPrim>
    {children}
  </div>
);

NameAvatar.propTypes = {
  profile: PropTypes.object,
  className: PropTypes.string,
  avatarClassName: PropTypes.string,
  target: PropTypes.string,
  highlightText: PropTypes.string,
  children: PropTypes.any,
  noLink: PropTypes.bool,
  prefix: PropTypes.string,
  icon: PropTypes.object,
  linkClassName: PropTypes.string,
  size: PropTypes.string,
  isStartupMultiClasses: PropTypes.bool,
  isCompanyRow: PropTypes.bool,
  customText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  passedTo: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default NameAvatar;
