/*
 * People List Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import { generateLoadedContentActions, generateFiltersActions, generateFiltersUiActions } from 'utils/filter/actions';

import {
  ADD_MARKED_PEOPLE_TO_MARKED_LISTS,
  CLEAR_MARKED_PEOPLE_LISTS,
  MARK_PEOPLE_LIST,
  SET_INVITE_COMPANY_MODAL,
  SET_PEOPLE_LISTS_MODAL,
  INCREASE_SEARCH_RESULTS_OFFSET,
  RESET_SEARCH_RESULTS_OFFSET,
  PEOPLE_PAGE_IDENTIFIER,
  SET_MERGE_MODAL, SELECT_MERGE_TARGET, REQUEST_MERGE, MERGE_SUCCESS, MERGE_FAILED, SET_INVITE_CLASS_COACH_MODAL,
  CLOSE_MOBILE_FILTERS_MODAL, OPEN_MOBILE_FILTERS_MODAL, TOGGLE_ACCORDION_ITEM,
  SET_IS_RECOMMENDATION_USEFUL,
  TOGGLE_PEOPLE_FILTERS,
  SET_ADD_TO_LIST_ERROR,
  SET_QS_FOR_EXPERIENCES_SEARCH,
} from './constants';

const setInviteCompanyModal = (isOpen) => ({ type: SET_INVITE_COMPANY_MODAL, isOpen });
const setInviteClassCoachModal = (isOpen) => ({ type: SET_INVITE_CLASS_COACH_MODAL, isOpen });
const setPeopleListsModal = (isOpen, isAddSearchResults) => ({ type: SET_PEOPLE_LISTS_MODAL, isOpen, isAddSearchResults });
const markPeopleList = (markedPeopleListRef, unselect) => ({ type: MARK_PEOPLE_LIST, markedPeopleListRef, unselect });
const clearMarkedPeopleLists = () => ({ type: CLEAR_MARKED_PEOPLE_LISTS });
const addMarkedPeopleToMarkedLists = () => ({ type: ADD_MARKED_PEOPLE_TO_MARKED_LISTS });
const increaseSearchResultsOffset = () => ({ type: INCREASE_SEARCH_RESULTS_OFFSET });
const resetSearchResultsOffset = () => ({ type: RESET_SEARCH_RESULTS_OFFSET });

const setMergeModal = (isOpen) => ({ type: SET_MERGE_MODAL, isOpen });
const selectMergeTarget = (targetRef) => ({ type: SELECT_MERGE_TARGET, targetRef });
const requestMerge = () => ({ type: REQUEST_MERGE });
const mergeSuccess = () => ({ type: MERGE_SUCCESS });
const mergeFailed = (error) => ({ type: MERGE_FAILED, error });
const openMobileFiltersModal = () => ({ type: OPEN_MOBILE_FILTERS_MODAL });
const closeMobileFiltersModal = () => ({ type: CLOSE_MOBILE_FILTERS_MODAL });
const toggleAccordionItem = (itemId) => ({ type: TOGGLE_ACCORDION_ITEM, itemId });

const setQsForExperiencesSearch = (qs) => ({ type: SET_QS_FOR_EXPERIENCES_SEARCH, qs });

// Did user like recommendation or not?
const setIsRecommendationUseful = (person, isUseful) => ({ type: SET_IS_RECOMMENDATION_USEFUL, person, isUseful });
const togglePeopleFilters = (filtersState) => ({ type: TOGGLE_PEOPLE_FILTERS, filtersState });

export const setAddToListError = (error) => ({ type: SET_ADD_TO_LIST_ERROR, error });

const {
  setSelectedObject,
  browseSelectedObject,
  markObject: markPerson,
  clearMarks,
  loadNextPage,
  loadNextPageSuccess,
  loadContent,
  contentLoaded,
  contentLoadingError,
  initialLoad,
  setHasMore,
  setTotalCount,
  unlistElement: unlistProfile,
} = generateLoadedContentActions(PEOPLE_PAGE_IDENTIFIER);

const {
  setSearch,
  setCategory,
  setTempFilter,
  setAdvFilter,
  removeTempFilter,
  removeAdvFilter,
  revertTempFilters,
  clearFilters,
  setFiltersAndSort,
  applyTempFilters,
  applyTempFiltersKey,
  applyTempFiltersGroup,
  setSort,
  toggleSort,
  setMaxPage,
  setAdvFiltersOperator,
  clearFiltersKey,
  clearFiltersGroup,
  setSearchOperator,
  setFilterGroupsOperators,
  setIndividualOptionsOperators,
  setTriedSearchWithoutFilters,
} = generateFiltersActions(PEOPLE_PAGE_IDENTIFIER);

const {
  openAdvFilterElement,
  closeAdvFilterElements,
  toggleAdvFiltersWindow,
  toggleFiltersUiSection,
  setIsSearchFocused,
} = generateFiltersUiActions(PEOPLE_PAGE_IDENTIFIER);

export {
  setSelectedObject,
  browseSelectedObject,
  markPerson,
  clearMarks,
  loadNextPage,
  loadNextPageSuccess,
  loadContent,
  contentLoaded,
  contentLoadingError,
  initialLoad,
  setSearch,
  setSearchOperator,
  setCategory,
  setTempFilter,
  setAdvFilter,
  removeTempFilter,
  removeAdvFilter,
  revertTempFilters,
  clearFilters,
  setFiltersAndSort,
  applyTempFilters,
  applyTempFiltersKey,
  clearFiltersKey,
  applyTempFiltersGroup,
  clearFiltersGroup,
  setSort,
  toggleSort,
  setMaxPage,
  setHasMore,
  setTotalCount,
  setAdvFiltersOperator,
  openAdvFilterElement,
  closeAdvFilterElements,
  toggleAdvFiltersWindow,
  toggleFiltersUiSection,
  setIsSearchFocused,
  setInviteCompanyModal,
  setInviteClassCoachModal,
  setPeopleListsModal,
  markPeopleList,
  clearMarkedPeopleLists,
  addMarkedPeopleToMarkedLists,
  increaseSearchResultsOffset,
  resetSearchResultsOffset,
  setMergeModal,
  selectMergeTarget,
  requestMerge,
  mergeSuccess,
  mergeFailed,
  unlistProfile,
  openMobileFiltersModal,
  closeMobileFiltersModal,
  toggleAccordionItem,
  setIsRecommendationUseful,
  togglePeopleFilters,
  setFilterGroupsOperators,
  setIndividualOptionsOperators,
  setTriedSearchWithoutFilters,
  setQsForExperiencesSearch,
};
