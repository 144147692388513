import { all, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';

import { logError } from 'utils/log';
import { updateObjFromApi } from 'utils/sagas';
import { makeSelectAccount, makeSelectAuthPrincipals } from 'containers/AuthProvider/selectors';
import { loadUserCompaniesData } from 'containers/AuthProvider/saga';

import { FOUNDER_COMPANY, SET_COMPANY_AND_CLASS } from './constants';
import { setFounderChooseModal } from './actions';


function* setCompanyAndClass(action) {
  try {
    const myAccount = yield select(makeSelectAccount());
    const userRoles = yield select(makeSelectAuthPrincipals());
    // before setting up company_aclass in localStorage,
    // validate the companyId and aclassId to make this context valid
    const companies = userRoles.filter((r) => r.includes('company_founder') || r.includes('company_employment'))
      .map((r) => Number(r?.split(':')?.[1]))
      .filter((r) => r);
    const aclasses = userRoles.filter((r) => r.includes('cxt_aclass_company'))
      .map((r) => Number(r?.split(':')?.[2]))
      .filter((r) => r);
    if (action.companyId
      && companies?.includes(action.companyId)
      && aclasses?.includes(action.aclassId)
      && action.aclassId
    ) {
      localStorage.setItem(FOUNDER_COMPANY, JSON.stringify({ company: action.companyId, aclass: action.aclassId }));
      yield all([
        yield put(setFounderChooseModal(false)),
        yield loadUserCompaniesData(),
        yield updateObjFromApi(myAccount, 'capabilities_rel'),
      ]);
      if (!action.disableRedirectHome) yield put(push('/'));
    }
  } catch (e) {
    logError(e);
  }
}

export default function* defaultSaga() {
  yield takeLatest(SET_COMPANY_AND_CLASS, setCompanyAndClass);
}
