import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

const BetaIcon = ({ className = '' }) => (
  <div className={classNames('dsa-beta-icon', className)}>
    βeta
  </div>
);

BetaIcon.propTypes = {
  className: PropTypes.string,
};

export default BetaIcon;
