import { fromJS, OrderedMap } from 'immutable';
import get from 'lodash/get';

import {
  SET_SELECTED_OBJECT, MARK_OBJECT, CLEAR_MARKS,
  INITIAL_LOAD, LOAD_CONTENT, LOAD_CONTENT_SUCCESS, LOAD_CONTENT_ERROR,
  LOAD_NEXT_PAGE, LOAD_NEXT_PAGE_SUCCESS, SET_HAS_MORE, SET_TOTAL_COUNT, SET_MAX_PAGE,
  SET_TEMP_FILTER, REMOVE_TEMP_FILTER, REMOVE_ADV_FILTER, SET_SEARCH, SET_CATEGORY, SET_FILTERS_AND_SORT, TOGGLE_SORT,
  APPLY_TEMP_FILTERS, REVERT_TEMP_FILTERS, SET_ADV_FILTERS_OPERATOR, AND_OP,
  APPLY_TEMP_FILTERS_GROUP, REVERT_TEMP_FILTERS_GROUP, CLEAR_FILTERS_GROUP, CLEAR_FILTERS,
  CLOSE_ADV_FILTER_ELEMENTS, OPEN_ADV_FILTER_ELEMENT, SET_IS_SEARCH_FOCUSED,
  TOGGLE_ADV_FILTERS_WINDOW, TOGGLE_FILTERS_UI_SECTION,
  UNLIST_ELEMENT,
  MARK_OBJECTS,
  BROWSE_SELECTED_OBJECT, SET_ADV_FILTER, CLEAR_FILTERS_KEY, APPLY_TEMP_FILTERS_KEY, SET_SORT,
  SET_SEARCH_OPERATOR,
  SET_MAX_PAGE_CONTENT,
  SET_FILTER_GROUPS_OPERATORS,
  SET_INDIVIDUAL_OPTIONS_OPERATORS,
  SET_SEARCH_WITHOUT_FILTERS,
  SET_TRIED_SEARCH_WITHOUT_FILTERS,
  SET_CONTENT_LOADING,
} from './constants';
import { groupMatchFilter, keyMatchFilter } from './filter';
import { copyFromTo, copyFromToFiltered, resetSectionFiltered, resetSection } from '../immutable';
import { toJS, formatStr, clamp, easyFieldsCompare } from '../general';
import { resRef } from '../refs';


// The initial state of the Page
const initialContentState = fromJS({
  routeParams: false,
  loading: false,
  error: false,
  loadedObjects: false,
  selectedObject: false,
  markedObjects: [],
  totalCount: 0,
  hasMore: false,
  triedSearchWithoutFilters: false,
});

/**
 * Generates a reducer for the loadedContent of a list-like page like People or Companies.
 *
 * @param page The name of the list-like page (e.g. People, Companies)
 *
 * @returns {loadedContent} a reducer for the loadedContent of a list-like page like People or Companies.
 */
export function generateLoadedContentReducer(page) {
  return function loadedContent(state = initialContentState, action) {
    switch (action.type) {
      case formatStr(INITIAL_LOAD, page):
        return state.set('routeParams', action.routeParams);
      case formatStr(SET_SELECTED_OBJECT, page):
        return state
          .set('selectedObject', action.selectedObject && resRef(action.selectedObject));
      case formatStr(BROWSE_SELECTED_OBJECT, page):
        return state.get('selectedObject') && state.get('loadedObjects')
          ? state.update(
            'selectedObject',
            (selectedObject) => {
              const loadedObjects = toJS(state.get('loadedObjects'));
              const selectedIndex = loadedObjects.findIndex((obj) => obj.id === toJS(selectedObject).id);
              const newIndex = clamp(0, loadedObjects.length - 1)(selectedIndex + action.change);
              return loadedObjects[newIndex];
            }
          )
          : state;
      case formatStr(MARK_OBJECT, page):
        return state.update('markedObjects', (markedObjects) => action.unselect
          ? markedObjects.filter((objectRef) => objectRef.id !== action.markedObjectRef.id)
          : markedObjects.push(action.markedObjectRef));
      case formatStr(MARK_OBJECTS, page):
        return state.update(
          'markedObjects',
          (markedObjects) => action.markedObjectsRefs
            ? markedObjects.push(...action.markedObjectsRefs
              .filter((markedObjectRef) => !markedObjects.find((alreadyMarkedObject) => alreadyMarkedObject.id === markedObjectRef.id)))
            : markedObjects
        );
      case formatStr(CLEAR_MARKS, page):
        return state.set('markedObjects', initialContentState.get('markedObjects'));
      case formatStr(LOAD_NEXT_PAGE, page):
        return state
          .set('loadingNextPage', true)
          .set('error', false);
      case formatStr(LOAD_NEXT_PAGE_SUCCESS, page):
        return state
          .set('loadingNextPage', false)
          .update('loadedObjects', (loadedObjects) => [...(loadedObjects || []), ...(action.objects || [])]);
      case formatStr(SET_HAS_MORE, page):
        return state.set('hasMore', action.hasMore);
      case formatStr(SET_TOTAL_COUNT, page):
        return state.set('totalCount', action.totalCount);
      case formatStr(LOAD_CONTENT, page):
      case formatStr(SET_SEARCH_WITHOUT_FILTERS, page):
        return state
          .set('loading', true)
          .set('error', false);
      case formatStr(LOAD_CONTENT_SUCCESS, page):
        return state
          .set('loading', false)
          .set('loadedObjects', action.objects);
      case formatStr(LOAD_CONTENT_ERROR, page):
        return state
          .set('error', action.error)
          .set('loading', false)
          .set('loadedObjects', false);
      case formatStr(UNLIST_ELEMENT, page):
        return state
          .update('loadedObjects', (loadedObjects) => loadedObjects.filter((obj) => action.obj.id !== obj.id))
          .update('markedObjects', (loadedObjects) => loadedObjects.filter((obj) => action.obj.id !== obj.id))
          .update('selectedObject', (selectedObj) => action.obj.id === selectedObj?.id ? null : selectedObj);
      case formatStr(SET_MAX_PAGE_CONTENT, page):
        return state.set('maxPage', action.pageNumber);
      case formatStr(SET_TRIED_SEARCH_WITHOUT_FILTERS, page):
        return state.set('triedSearchWithoutFilters', action.state);
      case formatStr(SET_CONTENT_LOADING, page):
        return state.set('loading', action.contentLoading);
      default:
        return state;
    }
  };
}


const baseTempFilters = {
  filters: [],
  fop: AND_OP,
  filterGroupsOperators: {},
  individualOptionsOperators: {},
};

const initialFilterStateJs = {
  sort: new OrderedMap(),
  maxPage: 0,
  tempFilters: baseTempFilters,
  appliedFilters: { search: '', category: null, advancedFilters: baseTempFilters },
};

const ADV_FILTERS_PATH = ['appliedFilters', 'advancedFilters', 'filters'];
const TMP_FILTERS_PATH = ['tempFilters', 'filters'];

const updateFiltersIfNotPresent = (action) => (filters) => filters.find((el) => easyFieldsCompare(el, action, ['field', 'value']))
  ? filters
  : filters.push({ field: action.field, value: action.value, isContains: action.isContains });

/**
 * Generates a reducer for the filter state of a list-like page like People or Companies.
 *
 * @param page                 The name of the list-like page (e.g. People, Companies)
 * @param overrideInitialState an object with overrides for the initialState
 *
 * @returns {loadedContent} a reducer for the filter state of a list-like page like People or Companies.
 */
export function generateFilterReducer(page, overrideInitialState) {
  const initialFilterState = fromJS(initialFilterStateJs).mergeDeep(overrideInitialState);
  return function filter(state = initialFilterState, action) {
    switch (action.type) {
      case formatStr(SET_TEMP_FILTER, page):
        return state.updateIn(TMP_FILTERS_PATH, updateFiltersIfNotPresent(action));
      case formatStr(SET_ADV_FILTER, page):
        return state.updateIn(ADV_FILTERS_PATH, updateFiltersIfNotPresent(action))
          .updateIn(TMP_FILTERS_PATH, updateFiltersIfNotPresent(action));
      case formatStr(REMOVE_TEMP_FILTER, page):
        return state
          .updateIn(
            TMP_FILTERS_PATH,
            (tmpFilters) => tmpFilters.filterNot((el) => easyFieldsCompare(el, action, ['field', 'value']))
          );
      case formatStr(REMOVE_ADV_FILTER, page):
        return state
          .updateIn(
            TMP_FILTERS_PATH,
            (tmpFilters) => tmpFilters.filterNot((el) => easyFieldsCompare(el, action, ['field', 'value']))
          )
          .updateIn(
            ADV_FILTERS_PATH,
            (tmpFilters) => tmpFilters.filterNot((el) => easyFieldsCompare(el, action, ['field', 'value']))
          );
      case formatStr(SET_SEARCH, page):
        return state.mergeDeep({ appliedFilters: { search: action.text.replace(/(^ +|[<>])/, '') } });
      case formatStr(SET_SEARCH_WITHOUT_FILTERS, page):
        return state.mergeDeep({ appliedFilters: { search: action.text.replace(/(^ +|[<>])/, '') } });
      case formatStr(SET_CATEGORY, page):
        return state.mergeDeep({ appliedFilters: { category: action.category } });
      case formatStr(APPLY_TEMP_FILTERS, page):
        return copyFromTo(state, ['tempFilters'], ['appliedFilters', 'advancedFilters']);
      case formatStr(APPLY_TEMP_FILTERS_GROUP, page):
        return copyFromToFiltered(state, TMP_FILTERS_PATH, ADV_FILTERS_PATH, groupMatchFilter(action.group));
      case formatStr(APPLY_TEMP_FILTERS_KEY, page):
        return copyFromToFiltered(state, TMP_FILTERS_PATH, ADV_FILTERS_PATH, keyMatchFilter(action.key, action.group));
      case formatStr(REVERT_TEMP_FILTERS, page):
        return copyFromTo(state, ['appliedFilters', 'advancedFilters'], ['tempFilters']);
      case formatStr(REVERT_TEMP_FILTERS_GROUP, page):
        return copyFromToFiltered(state, ADV_FILTERS_PATH, TMP_FILTERS_PATH, groupMatchFilter(action.group));
      case formatStr(CLEAR_FILTERS_GROUP, page):
        return resetSectionFiltered(
          resetSectionFiltered(state, initialFilterState, TMP_FILTERS_PATH, groupMatchFilter(action.group)),
          initialFilterState,
          ADV_FILTERS_PATH,
          groupMatchFilter(action.group)
        );
      case formatStr(CLEAR_FILTERS_KEY, page):
        return resetSectionFiltered(
          resetSectionFiltered(state, initialFilterState, TMP_FILTERS_PATH, keyMatchFilter(action.key, action.group)),
          initialFilterState,
          ADV_FILTERS_PATH,
          keyMatchFilter(action.key, action.group)
        );
      case formatStr(CLEAR_FILTERS, page):
        return resetSection(
          resetSection(
            state.deleteIn(['tempFilters', 'searchOp']).deleteIn(['appliedFilters', 'searchOp']),
            initialFilterState,

            TMP_FILTERS_PATH
          ),
          initialFilterState,
          ADV_FILTERS_PATH
        );
      case formatStr(SET_FILTERS_AND_SORT, page):
        // ToDO: DRY this
        return state
          .setIn(
            ['tempFilters', 'fop'],
            get(action, ['filtersObj', 'advancedFilters', 'fop'])
            || initialFilterState.getIn(['tempFilters', 'fop'])
          )
          .setIn(
            TMP_FILTERS_PATH,
            fromJS(get(action, ['filtersObj', 'advancedFilters', 'filters'])
              || initialFilterState.getIn(TMP_FILTERS_PATH))
          )
          .setIn(
            ['appliedFilters', 'category'],
            action.filtersObj.category || initialFilterState.getIn(['appliedFilters', 'category'])
          )
          .setIn(
            ['appliedFilters', 'search'],
            action.filtersObj.search || initialFilterState.getIn(['appliedFilters', 'search'])
          )
          .setIn(
            ['appliedFilters', 'advancedFilters', 'fop'],
            get(action, ['filtersObj', 'advancedFilters', 'fop'])
            || initialFilterState.getIn(['tempFilters', 'fop'])
          )
          .setIn(
            ADV_FILTERS_PATH,
            fromJS(get(action, ['filtersObj', 'advancedFilters', 'filters'])
              || initialFilterState.getIn(ADV_FILTERS_PATH))
          )
          .setIn(
            ['tempFilters', 'filterGroupsOperators'],
            get(action, ['filtersObj', 'advancedFilters', 'filterGroupsOperators'])
            || initialFilterState.getIn(['tempFilters', 'filterGroupsOperators'])
          )
          .setIn(
            ['appliedFilters', 'advancedFilters', 'filterGroupsOperators'],
            get(action, ['filtersObj', 'advancedFilters', 'filterGroupsOperators'])
            || initialFilterState.getIn(['tempFilters', 'filterGroupsOperators'])
          )
          .setIn(
            ['tempFilters', 'individualOptionsOperators'],
            get(action, ['filtersObj', 'advancedFilters', 'individualOptionsOperators'])
            || initialFilterState.getIn(['tempFilters', 'individualOptionsOperators'])
          )
          .setIn(
            ['appliedFilters', 'advancedFilters', 'individualOptionsOperators'],
            get(action, ['filtersObj', 'advancedFilters', 'individualOptionsOperators'])
            || initialFilterState.getIn(['tempFilters', 'individualOptionsOperators'])
          )
          .set('sort', action.sort ? OrderedMap(action.sort) : initialFilterState.get('sort'));
      case formatStr(SET_SORT, page):
        return state.set('sort', fromJS({ [action.sortKey]: action.order || 1 }));
      case formatStr(TOGGLE_SORT, page):
        return state.update('sort', updateSortState(action, 1));
      case formatStr(SET_MAX_PAGE, page):
        return state.set('maxPage', action.pageNumber);
      case formatStr(SET_ADV_FILTERS_OPERATOR, page):
        return state.setIn(['tempFilters', 'fop'], action.operator)
          .setIn(['appliedFilters', 'advancedFilters', 'fop'], action.operator);
      case formatStr(SET_SEARCH_OPERATOR, page):
        return state.setIn(['tempFilters', 'searchOp'], action.operator)
          .setIn(['appliedFilters', 'searchOp'], action.operator)
          .mergeDeep({ appliedFilters: { search: state.get('appliedFilters').get('search') } });
      case formatStr(SET_FILTER_GROUPS_OPERATORS, page):
        return state.setIn(['tempFilters', 'filterGroupsOperators'], action.operatorsObj)
          .setIn(['appliedFilters', 'advancedFilters', 'filterGroupsOperators'], action.operatorsObj);
      case formatStr(SET_INDIVIDUAL_OPTIONS_OPERATORS, page):
        return state.setIn(['tempFilters', 'individualOptionsOperators'], action.operatorsObj)
          .setIn(['appliedFilters', 'advancedFilters', 'individualOptionsOperators'], action.operatorsObj);
      default:
        return state;
    }
  };
}

export const updateSortState = (action, defaultDefaultOrder = 1) => (sortState) => {
  const { sortKey } = action;
  const currentSortOrder = sortState.get(sortKey);
  const defaultOrder = action.defaultOrder || defaultDefaultOrder;
  if (!currentSortOrder) {
    return sortState.clear().set(sortKey, defaultOrder);
  }
  if (currentSortOrder === defaultOrder) {
    return sortState.set(sortKey, currentSortOrder * -1);
  }
  return sortState.remove(sortKey);
};

const initialFiltersUIState = fromJS({
  isAdvFiltersOpen: false,
  selectedAdvFilter: false,
  isSearchFocused: false,
  openSection: false,
});

/**
 * Generates a reducer for the Ui state of the filters (e.g. adv filters opened, search element is focused).
 *
 * @param page               The name of the list-like page (e.g. People, Companies)
 *
 * @returns {loadedContent} a reducer for the filter state of a list-like page like People or Companies.
 */
export function generateFiltersUiReducer(page) {
  return function filtersUi(state = initialFiltersUIState, action) {
    switch (action.type) {
      case formatStr(OPEN_ADV_FILTER_ELEMENT, page):
        return state.set('selectedAdvFilter', action.advFilter);
      case formatStr(CLOSE_ADV_FILTER_ELEMENTS, page):
        return state.set('selectedAdvFilter', false);
      case formatStr(TOGGLE_ADV_FILTERS_WINDOW, page):
        return state.update('isAdvFiltersOpen', (isAdvFiltersOpen) => !isAdvFiltersOpen)
          .set('isCategoriesDropdownOpen', false);
      case formatStr(TOGGLE_FILTERS_UI_SECTION, page):
        return state.update('openSection', (openSection) => action.section !== openSection && action.section)
          .set('isAdvFiltersOpen', false);
      case formatStr(SET_IS_SEARCH_FOCUSED, page):
        return state.set('isSearchFocused', action.isSearchFocused);
      default:
        return state;
    }
  };
}

export const generateAllReducers = (page, overrideInitialState) => ({
  loadedContent: generateLoadedContentReducer(page),
  filter: generateFilterReducer(page, overrideInitialState),
  filtersUi: generateFiltersUiReducer(page),
});
