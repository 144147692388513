import { delay, call, put, takeLatest, select } from 'redux-saga/effects';
import { change, stopSubmit, submit } from 'redux-form/immutable';

import { updateIncluded, updateIncludedFromRequest } from 'actions';
import { API_SHARED_COMPANIES_LISTS_BASE_URL } from 'containers/App/constants';
import { makeSelectSharedListByRef } from 'containers/Forms/SharedCompanies/selectors';
import { showAlert } from 'containers/AlertBox/actions';
import { makeSelectObject } from 'containers/App/selectors';
import { formDataToJApi } from 'utils/formToJsonApi';
import { extractData } from 'utils/jsonApiExtract';
import { isFieldChange, requestFunctionForObject } from 'utils/Forms/general';
import { post } from 'utils/request';
import { handleError } from 'utils/Forms/sagas';
import { pushLocation } from 'utils/sagas';
import { clearMarks } from 'containers/Companies/Search/actions';

import {
  PREVIEW_SHARED_COMPANIES_LIST,
  SAMPLE_SHAREDLISTS,
  SAMPLE_SHAREDLISTS_FOUNDER_INVITE,
  SAVE_SHARED_COMPANIES_LIST,
  SAVE_SHARED_COMPANIES_LIST_DRAFT,
  SEND_SHARED_COMPANIES_FOUNDER_INVITES,
  SEND_SHARED_COMPANIES_LIST,
} from '../constants';
import { setPreviewedSharedList, toggleSharedListPreview } from './actions';
import { MESSAGE_TYPE_NICE_MAPPER, SHARED_COMPANIES_LIST_FORM_NAME, SHARED_LIST_SUBJECT } from './constants';


export function* saveSharedCompaniesListSaga(action) {
  const { preview } = action.values;
  let formData = action.values;
  const sharedList = yield select(makeSelectSharedListByRef(action.objRef));

  const isDraft = formData.draft === undefined ? (sharedList && sharedList.draft) : formData.draft;
  if (!isDraft) {
    formData = { ...formData, message: formData.message_html };
  }

  const baseURL = `${API_SHARED_COMPANIES_LISTS_BASE_URL}${action.objRef ? `/${action.objRef.id}` : ''}`;
  try {
    const sharedListsRequest = yield call(
      requestFunctionForObject(action.objRef),
      `${baseURL}?include=members,recipients,cc,bcc${preview ? '&_preview=1' : ''}`,
      { data: formDataToJApi({ ...SAMPLE_SHAREDLISTS, ...action.objRef }, formData) }
    );
    const { inclusions, items: [sharedListRef] } = extractData(sharedListsRequest);
    yield put(updateIncluded(inclusions));

    if (action.successAction) yield put(action.successAction);

    if (preview) {
      yield put(setPreviewedSharedList(sharedListRef));
      yield put(toggleSharedListPreview(true));
      yield put(stopSubmit(SHARED_COMPANIES_LIST_FORM_NAME, {}));
      return;
    }

    const { recipients, message_type: messageType, name } = yield select(makeSelectObject(sharedListRef));
    const alertFirstPart = `Success! ${MESSAGE_TYPE_NICE_MAPPER[messageType]} Shared List, ${name}, was`;
    const alertSecondPart = isDraft ? 'saved as draft' : `sent to ${recipients && recipients().nicename}.`;
    yield put(showAlert(`${alertFirstPart} ${alertSecondPart}`));

    if (!action.objRef) { // Save AND CLOSE, that's why the ternary
      yield put(clearMarks());
      yield pushLocation(!isDraft ? `/companies/lists/${sharedListsRequest.data.id}` : '/companies');
    } else if (isDraft) { // Save AND CLOSE
      yield pushLocation('/companies');
    }
  } catch (err) {
    yield handleError(err, action.section);
  }
}

export function* sendSharedCompaniesFounderInvites(action) {
  try {
    let formData = action.values;
    formData = { ...formData, message: formData.message_html };
    const sharedListsRequest = yield call(
      post,
      `${API_SHARED_COMPANIES_LISTS_BASE_URL}/${action.objRef.id}/invite`,
      { data: formDataToJApi({ ...SAMPLE_SHAREDLISTS_FOUNDER_INVITE, ...action.objRef }, formData) }
    );
    yield put(updateIncludedFromRequest(sharedListsRequest));
    if (action.successAction) yield put(action.successAction);
  } catch (err) {
    yield handleError(err, action.section);
  }
}

export function* saveSharedCompaniesListDraftSaga(action) {
  yield put(change(action.section, 'draft', true));
  yield put(change(action.section, 'preview', false));
  yield delay(300);
  yield put(submit(action.section));
}

export function* sendSharedCompaniesListSaga(action) {
  yield put(change(action.section, 'draft', false));
  yield put(change(action.section, 'preview', false));
  yield delay(300);
  yield put(submit(action.section));
}

export function* previewSharedCompaniesListSaga(action) {
  yield put(change(action.section, 'preview', true));
  yield delay(300);
  yield put(submit(action.section));
}

export function* setMessageTemplates(action) {
  const newMessageeType = action.payload;

  yield put(change(action.meta.form, 'subject', SHARED_LIST_SUBJECT[newMessageeType]));
}

export default function* defaultSaga() {
  yield takeLatest(SAVE_SHARED_COMPANIES_LIST, saveSharedCompaniesListSaga);
  yield takeLatest(SAVE_SHARED_COMPANIES_LIST_DRAFT, saveSharedCompaniesListDraftSaga);
  yield takeLatest(SEND_SHARED_COMPANIES_LIST, sendSharedCompaniesListSaga);
  yield takeLatest(SEND_SHARED_COMPANIES_FOUNDER_INVITES, sendSharedCompaniesFounderInvites);
  yield takeLatest(PREVIEW_SHARED_COMPANIES_LIST, previewSharedCompaniesListSaga);
  yield takeLatest(isFieldChange(SHARED_COMPANIES_LIST_FORM_NAME, 'message_type'), setMessageTemplates);
}
