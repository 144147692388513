import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectCreatable from 'react-select/creatable';
import isFunction from 'lodash/isFunction';
import { flatten } from 'lodash';

import { toJS } from 'utils/general';
import { errorClassNames } from 'utils/Forms/general';
import { extractTextBetweenAngleBrackets } from 'utils/inputHelpers';

import reduxFormProps from '../reduxFormProps';
import FullFieldHoc from '../HoC/FullFieldHoc';
import SimpleFieldHoc from '../HoC/SimpleFieldHoc';
import { OptionsFromSchemaHoc } from '../HoC/OptionsFromSchemaHoc';
import { DropdownIndicator, ClearIndicator } from './SelectComponents';
import SimplifyReduxFormInput from '../HoC/SimplifyReduxFormInput';


export const ReactSelectAdapter = ({
  defaultValue = null, options, required, labelKey, removeValue, input, meta, multi, valueKey, isNarrow, simpleValue, disabled, creatable, formatOptionLabel, formatCreateLabel, placeholder,
  getOptionLabel, filterOption, passedComponents = {}, isGroupedOptions = false, compareByValuesInAngleBrackets, isSearchableLength = 5, ...props
}) => {
  const SelectComponent = creatable ? SelectCreatable : Select;
  let value = toJS(isFunction(input.value) ? input.value() : input.value);

  const vKey = valueKey || 'value';
  const lKey = labelKey || 'label';
  const getOptionValue = (option) => option && option[vKey];

  let simpleValOnChange;
  if (simpleValue) {
    simpleValOnChange = (v) => input.onChange(Array.isArray(v) ? v.map(getOptionValue) : getOptionValue(v));
    const findValueObj = (val) => {
      const newOptions = isGroupedOptions ? flatten(options?.map((opt) => opt.options)) : options;
      const label = compareByValuesInAngleBrackets
        ? ((newOptions?.find((option) => (val === option[vKey])
          // if option and val strings has some values inside angle brackets (e.g. "Danielle D'Agostaro <danielle@alchemistaccelerator.com>"),
          // compare these values and return label if they're same. it's needed for cases when we pull values from  Vault senders/signatures
          // and if anything changes in the signatures name. then old value is not being displayed, but it's still valid value and same person, just with some changes in name.
          // for example, straight quote (') could be changed to curly quote (’).
          || (
            (extractTextBetweenAngleBrackets(val) !== '' && extractTextBetweenAngleBrackets(option[vKey]) !== '')
            && extractTextBetweenAngleBrackets(val) === extractTextBetweenAngleBrackets(option[vKey])
          ))
        ) || val)
        : ((newOptions?.find((option) => val === option[vKey])) || val);
      return label && creatable && typeof label === 'string' ? { value: label, label } : label;
    };
    value = Array.isArray(value) ? value.map(findValueObj) : findValueObj(value);
  }

  return (
    <SelectComponent
      closeOnSelect={!multi}
      isSearchable={options?.length > isSearchableLength}
      isClearable={!required}
      getOptionLabel={getOptionLabel || ((option) => option[lKey])}
      getOptionValue={getOptionValue}
      classNamePrefix="Select"
      openMenuOnFocus
      isMulti={multi}
      isDisabled={disabled}
      formatOptionLabel={formatOptionLabel}
      formatCreateLabel={formatCreateLabel}
      filterOption={filterOption}
      placeholder={placeholder}
      {...props}
      value={(!removeValue && value) || defaultValue}
      onChange={simpleValOnChange || input.onChange}
      onBlur={() => input.onBlur()}
      onFocus={input?.onFocus}
      options={options}
      components={{
        DropdownIndicator,
        ClearIndicator,
        ...passedComponents,
      }}
      className={
        errorClassNames(
          { 'dsa-select-prim _md_': true, 'dsa-select__no-caret narrow-select': isNarrow && value },
          meta,
        )}
    />);
};

ReactSelectAdapter.propTypes = {
  ...reduxFormProps,
  defaultValue: PropTypes.object,
  required: PropTypes.bool,
  isNarrow: PropTypes.bool,
  options: PropTypes.array,
  labelKey: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  creatable: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  formatCreateLabel: PropTypes.func,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  filterOption: PropTypes.func,
  passedComponents: PropTypes.object,
  isGroupedOptions: PropTypes.bool,
  compareByValuesInAngleBrackets: PropTypes.bool,
  isSearchableLength: PropTypes.number,
};
const ReactSelectAdapterWithSchemaOpts = OptionsFromSchemaHoc(ReactSelectAdapter);

export const SelectInput = FullFieldHoc(ReactSelectAdapterWithSchemaOpts, { colSize: 'col-50' });
export const SelectInputField = SimpleFieldHoc(ReactSelectAdapterWithSchemaOpts, { colSize: 'col-50' });
export const NonFormSelectInputField = SimplifyReduxFormInput(SelectInputField);
export const NonFormSelectInput = SimplifyReduxFormInput(SelectInput);
