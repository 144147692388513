import intersection from 'lodash/intersection';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { PERMISSION } from 'containers/App/constants';
import { reloginPath } from 'utils/auth';
import { setLastPage, extractIdFromUrl } from 'utils/general';
import { makeSelectLocation, makeSelectQueryParam } from 'containers/App/selectors';

import { selectIsLoggingOut, makeSelectAuthPrincipals, makeSelectIsAuthenticated, selectHasAccount } from './selectors';


const AuthRoute = ({
  userRoles, isAuthTried, roles, isLoggingOut, userHasAccount, location, isDemoDayRoute, render, unauthHash, unauthMail, ...rest
}) => {
  const registerProfileFormPath = '/register-profile/form';
  const isAuthorized = (isAuthTried && roles === 'all') || intersection(userRoles, roles).length > 0;
  if (isLoggingOut) {
    return null;
  }
  if (!isAuthorized && !isAuthTried) {
    setLastPage();
    if (isDemoDayRoute) {
      const aaClassNumber = extractIdFromUrl(location.pathname);
      const isAlchemistX = location.pathname.includes('/ax/');
      const demodayLoginUrl = aaClassNumber ? `/demo-day/${isAlchemistX ? 'ax' : 'aa'}/${aaClassNumber}/sign-in${location.search || ''}` : '';
      window.location.href = reloginPath(null, location.pathname.includes(registerProfileFormPath) ? '/register-profile' : demodayLoginUrl);
      return null;
    }

    if (location.pathname.includes('/onboarding/') && (location.search.includes('type=founder') || location.search.includes('type=class-coach'))) {
      return <Redirect to={`/welcome${location.search}`} />;
    }

    if (location.pathname.includes('/onboarding/') && location.search.includes('type=limited-partner')) {
      return <Redirect to={`/welcome/limited-partner${location.search}`} />;
    }
    if (location.pathname.includes('/onboarding/') && location.search.includes('type=partner')) {
      return <Redirect to={`/welcome/partner${location.search}`} />;
    }

    if (location.pathname.includes('/onboarding/') && location.search.includes('type=corporate-innovator')) {
      return <Redirect to={`/welcome/corporate-innovator${location.search}`} />;
    }

    // redirect unauth users from /onboarding to onboarding sign-up / sign-in page
    if (location.pathname.includes('/onboarding/') && unauthHash && unauthMail) {
      return <Redirect to={`/welcome?code=${unauthHash}&_mail=${unauthMail}`} />;
    }

    window.location.href = reloginPath(null, location.pathname.includes(registerProfileFormPath) ? '/register-profile' : '', location.search);
    return null;
  }

  // !routes.includes(location.pathname) - makes it possible for users (who are in `/register-profile/form`)
  // to go back to DD/IFS registration form
  if (!routes.includes(location.pathname) && location.pathname !== registerProfileFormPath && !userHasAccount) {
    return <Redirect to={`${registerProfileFormPath}${location.search}`} />;
  }

  if (isAuthTried && isAuthorized) {
    return <Route render={render} {...rest} />;
  }

  return <Redirect to="/" />;
};

AuthRoute.propTypes = {
  path: PropTypes.string,
  isAuthTried: PropTypes.bool,
  isLoggingOut: PropTypes.bool,
  userRoles: PropTypes.arrayOf(PropTypes.string),
  userHasAccount: PropTypes.bool,
  location: PropTypes.object,
  roles: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  isDemoDayRoute: PropTypes.bool,
  render: PropTypes.func,
  unauthHash: PropTypes.string,
  unauthMail: PropTypes.string,
};

AuthRoute.defaultProps = {
  roles: [PERMISSION.founder, PERMISSION.class_coach, PERMISSION.admin],
};

const mapStateToProps = createStructuredSelector({
  userRoles: makeSelectAuthPrincipals(),
  userHasAccount: selectHasAccount,
  isAuthTried: makeSelectIsAuthenticated(),
  isLoggingOut: selectIsLoggingOut,
  location: makeSelectLocation(),
  unauthHash: makeSelectQueryParam('code'),
  unauthMail: makeSelectQueryParam('_mail'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(AuthRoute);

const routes = [
  '/demo-day/attendance',
  '/ifs/attendance',
];
