import { call, all, put, select } from 'redux-saga/effects';
import { change, arrayRemove, stopSubmit } from 'redux-form/immutable';
import isEmpty from 'lodash/isEmpty';

import { updateIncludedFromRequest } from 'actions';
import { getRegisteredField } from 'containers/Forms/selectors';
import { SERVER_ERROR } from 'containers/Forms/constants';

import { isUniquenessError, splitResponsesAndErrors } from './general';
import { putActions, updateObjFromApi } from '../sagas';

export function* callAndCatch(...callParams) {
  try {
    const response = yield call(...callParams);
    return { success: true, response };
  } catch (err) {
    // const shouldShowErrorAlert = callParams.some((param) => !!param.showErrorAlert);
    // const { errorJson } = yield preprocessError(err);
    // if (shouldShowErrorAlert && errorJson && errorJson.errors && errorJson.errors[0] && errorJson.errors[0].detail) {
    //   yield put(showAlert(errorJson.errors[0].detail, COLOR.danger));
    // }
    const error = yield processError(err);
    return { success: false, error };
  }
}
export function* handleError(err, section) {
  const error = yield processError(err, section);
  yield put(stopSubmit(section, error));
}

export class ProcessedError extends Error {
  constructor(field, msg) {
    super();
    this.processedError = { field, msg };
  }
}

export function* processError(rawError, formName) {
  const err = yield preprocessError(rawError);

  if (err.errorJson) {
    const { errorJson } = err;
    return yield processJsonApiErrors(errorJson);
  } if (err.processedError) {
    const registeredField = formName && (yield select(getRegisteredField(formName, err.processedError.field)));
    return {
      [err.processedError.field]: registeredField === 'FieldArray'
        ? { _error: err.processedError.msg }
        : err.processedError.msg,
    };
  }
  // eslint-disable-next-line no-underscore-dangle
  return { _error: err.serverError };
}

export function* processJsonApiErrors(errorJson, formName) {
  const errorObj = {};
  if (errorJson.errors && Array.isArray(errorJson.errors)) {
    for (let i = 0; i < errorJson.errors.length; i++) {
      const currentError = errorJson.errors[i];
      // ToDo: evaluate using the frontend errors mapper
      const currentErrorDetail = currentError.detail === 'Required' ? 'HIDE_BOX' : currentError.detail;
      const fieldName = currentError.source && currentError.source._key; // eslint-disable-line no-underscore-dangle

      const registeredField = formName && fieldName && (yield select(getRegisteredField(formName, fieldName)));
      errorObj[fieldName || '_error'] = registeredField && registeredField.type === 'FieldArray'
        ? { _error: currentErrorDetail }
        : currentErrorDetail;
    }
  } else {
    // eslint-disable-next-line no-underscore-dangle
    errorObj._error = isUniquenessError(errorJson.errors) ? 'Not unique' : SERVER_ERROR;
  }
  return errorObj;
}

export function* preprocessError(err) {
  if (!err.response) return err;

  try {
    return { errorJson: yield err.response.json() };
  } catch (e) {
    return { serverError: err.toString() };
  }
}

export function* arrayRemoveMany(formName, field, successIdxs) {
  for (let j = successIdxs.length - 1; j >= 0; j -= 1) {
    yield put(arrayRemove(formName, field, successIdxs[j]));
  }
}

export function* preHandleCallAndCatches(requests, action, multiFormMainField) {
  const {
    responses, errors, idxToId, deletedIdxs,
  } = splitResponsesAndErrors(requests, multiFormMainField);
  yield all(responses
    .filter((resp) => resp.data)
    .map((rowRequest) => put(updateIncludedFromRequest(rowRequest))));

  yield arrayRemoveMany(action.section, multiFormMainField, deletedIdxs);
  yield all(idxToId.map((idxId) => put(change(action.section, `${multiFormMainField}[${idxId.idx}].id`, idxId.id))));

  return errors;
}

export function* handleCallAndCatches(requests, action, multiFormMainField) {
  const errors = yield preHandleCallAndCatches(requests, action, multiFormMainField);
  if (isEmpty(errors)) {
    yield putActions(action.successAction);
    return true;
  }

  yield put(stopSubmit(action.section, errors));
  return false;
}

export function* handleAtomicCallAndCatches(requests, action, multiFormMainField) {
  const results = (requests || []).map((r) => {
    if (r.errors) {
      const err = Array.isArray(r.errors) ? r.errors[0] : r.errors;
      return err?.status === 424 ? 'Something went wrong' : err?.detail;
    } if ([404, 424, 500].includes(r.code)) {
      return r.detail || r.status || 'Something went wrong';
    }
    return null;
  });
  if (results.every((value) => value === null)) {
    yield putActions(action.successAction);
    return true;
  }

  if (action?.section) {
    yield put(stopSubmit(action.section, multiFormMainField ? { [multiFormMainField]: {
      _error: results.filter((r) => r),
    } } : {
      _error: results.filter((r) => r),
    }));
  } else {
    return results.filter((r) => r);
  }

  return false;
}

export function* saveArrayField(action, arrayField, individualSaga, apiField, parentObjRef) {
  const items = action.values[arrayField];
  if (!items) return null;

  const nonDeletedItems = items.map((item) => item.isDeleted || item.is_deleted ? null : item);
  const deletedItems = items.map((item) => item.isDeleted || item.is_deleted ? item : null);

  const nonDelItemRequests = yield sendArray(nonDeletedItems, individualSaga, parentObjRef || action.objRef, action);
  const delItemRequests = yield sendArray(deletedItems, individualSaga, parentObjRef || action.objRef, action);
  const itemRequests = arrayCombine(nonDelItemRequests, delItemRequests);

  const errors = yield preHandleCallAndCatches(itemRequests, action, arrayField);
  yield updateObjFromApi(parentObjRef || action.objRef, apiField || arrayField);
  return errors;
}

function* sendArray(itemsArray, individualSaga, objRef, action) {
  // return yield all(itemsArray.map((item, idx) => item && callAndCatch(individualSaga, item, objRef, idx, action, { showErrorAlert: true })));
  return yield all(itemsArray.map((item, idx) => item && callAndCatch(individualSaga, item, objRef, idx, action)));
}


const arrayCombine = (arr1, arr2) => arr1.map((el, idx) => el || arr2[idx]);
