import { generateFiltersActions, generateFiltersUiActions, generateLoadedContentActions } from 'utils/filter/actions';

import {
  CLOSE_MOBILE_FILTERS_MODAL,
  COMPANIES_PAGE_IDENTIFIER,
  DOWNLOAD_CSV,
  OPEN_MOBILE_FILTERS_MODAL,
  RETURN_TO_COMPANY_LIST,
  SELECT_COMPANY_LIST,
  SET_CSV_LOADED,
  SET_SELECT_ALL_LOADED,
  SET_SELECT_ALL_LOADING,
  TOGGLE_ACCORDION_ITEM,
  TOGGLE_COMPANIES_FILTERS,
} from './constants';

const {
  markObject: markCompany,
  markObjects: markCompanies,
  setSelectedObject: setSelectedCompany,
  markAll: markAllCompanies,
  browseSelectedObject,
  clearMarks,
  loadNextPage,
  loadNextPageSuccess,
  loadContent,
  contentLoaded,
  contentLoadingError,
  initialLoad,
  setHasMore,
  setTotalCount,
} = generateLoadedContentActions(COMPANIES_PAGE_IDENTIFIER);

const {
  setSearch,
  setCategory,
  setFiltersAndSort,
  toggleSort,
  setMaxPage,
  setAdvFilter,
  setTempFilter,
  removeAdvFilter,
  removeTempFilter,
  applyTempFiltersGroup,
  clearFiltersGroup,
  clearFilters,
  setAdvFiltersOperator,
  setFilterGroupsOperators,
  setIndividualOptionsOperators,
  setSearchWithoutFilters,
  setTriedSearchWithoutFilters,
} = generateFiltersActions(COMPANIES_PAGE_IDENTIFIER);

const downloadCSV = () => ({ type: DOWNLOAD_CSV });
const selectCompanyList = (selectedCompanyListRef) => ({ type: SELECT_COMPANY_LIST, selectedCompanyListRef });
const returnToCompanyList = () => ({ type: RETURN_TO_COMPANY_LIST });
const openMobileFiltersModal = () => ({ type: OPEN_MOBILE_FILTERS_MODAL });
const closeMobileFiltersModal = () => ({ type: CLOSE_MOBILE_FILTERS_MODAL });

const setSelectAllLoading = () => ({ type: SET_SELECT_ALL_LOADING });
const setSelectAllLoaded = () => ({ type: SET_SELECT_ALL_LOADED });

const setCsvLoaded = () => ({ type: SET_CSV_LOADED });

const {
  openAdvFilterElement,
  setIsSearchFocused,
} = generateFiltersUiActions(COMPANIES_PAGE_IDENTIFIER);

const toggleCompaniesFilters = (filtersState) => ({ type: TOGGLE_COMPANIES_FILTERS, filtersState });
const toggleAccordionItem = (itemId) => ({ type: TOGGLE_ACCORDION_ITEM, itemId });

export {
  loadNextPage,
  loadNextPageSuccess,
  loadContent,
  contentLoaded,
  contentLoadingError,
  initialLoad,
  setSearch,
  setCategory,
  setAdvFilter,
  setTempFilter,
  removeTempFilter,
  removeAdvFilter,
  openAdvFilterElement,
  applyTempFiltersGroup,
  clearFiltersGroup,
  clearFilters,
  setFiltersAndSort,
  toggleSort,
  setMaxPage,
  setHasMore,
  setTotalCount,
  setIsSearchFocused,
  markCompany,
  markCompanies,
  markAllCompanies,
  setSelectedCompany,
  clearMarks,
  downloadCSV,
  selectCompanyList,
  returnToCompanyList,
  browseSelectedObject,
  openMobileFiltersModal,
  closeMobileFiltersModal,
  setSelectAllLoading,
  setSelectAllLoaded,
  setCsvLoaded,
  toggleCompaniesFilters,
  setAdvFiltersOperator,
  setFilterGroupsOperators,
  setIndividualOptionsOperators,
  setSearchWithoutFilters,
  toggleAccordionItem,
  setTriedSearchWithoutFilters,
};
